<template>
  <component
    :is="tag"
    v-on="{
      ...$listeners,
      mousedown: ($event) => $event.preventDefault(),
    }"
    v-bind="$attrs"
    :class="[buttonStyle, 'button']"
    type="button"
  >

    <!-- @slot Custom icon -->
    <slot name="icon"></slot>

    <!-- @slot Custom content -->
    <slot>{{ $attrs.value }}</slot>

    <Loader
      v-if="isLoading"
      :size="'sm'"
      class="ml-sm">
    </Loader>
  </component>
</template>

<script>

import Loader     from '../Loader/a-Loader.vue';


export default {
  name: 'a-Button',
  components: {
    Loader,
  },
  props: {
    isFluid: {
      type: Boolean,
      default: false
    },
    isGhost: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isGrey: {
      type: Boolean,
      default: false
    },
    hasNoBorder: {
      type: Boolean,
      default: false
    },
    isTransparentBackground: {
      type: Boolean,
      default: false
    },
    hasGradientFade: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: 'primary',
      validator: color => ['primary', 'light', 'white', 'neutral', 'dark', 'valid', 'warning', 'alert', 'black'].includes(color)
    },
    tag: {
      type: String,
      default: 'button',
      validator: tag => ['button', 'a', 'span'].includes(tag),
    }
  },
  computed: {
    buttonStyle() {
      return {
        'button--fluid': this.isFluid,
        'button--ghost': this.isGhost,
        'button--small': this.isSmall,
        'button--small--ghost': this.isSmall && this.isGhost,
        'button--white': this.isWhite,
        'button--transparent': this.isTransparentBackground,
        'button--grey': this.isGrey,
        'button--no-border': this.hasNoBorder,
        'button--gradient-fade': !this.$attrs.disabled && !this.isGhost && !this.isWhite && !this.isTransparentBackground && !this.isGrey,
      };
    },
  },
}
</script>

<style lang="scss">

.button {
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--space-main-height);
  padding: 0 var(--space-md);
  color: var(--color-white);
  background: var(--color-black);
  font-family: var(--font-stack-secondary);
  font-size: var(--text-sm);
  font-weight: var(--font-semi);
  line-height: 2;
  white-space: normal;
  border-radius: var(--rounded-xs);
  user-select: none;

  @include textOverflowEllipsis();

  &:disabled {
    color: var(--color-grey-neutral);
    background: var(--color-grey-semi);
    cursor: not-allowed;

    svg {
      fill: var(--color-grey-neutral);
    }
  }

  &--gradient-fade {
    &:hover, &:focus {
      color: var(--color-white);
      animation: gradient-fade .175s ease-out 0s 1 reverse both;
      overflow: initial;

      &:before {
        z-index: -1;
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background: var(--color-gradient-main);
        filter: blur(10px);
        opacity: .75;
      }

      * {
        color: var(--color-white);
      }
    }
  }

  &--fluid {
    width: 100%;
  }

  &--ghost {
    color: var(--color-black);
    padding: 0 var(--space-sm);
    background: transparent;
  }

  &--small {
    height: var(--space-lg);
    border-radius: var(--rounded-sm);
    font-size: var(--text-xs);
  }

  &--white {
    color: var(--color-black);
    background: var(--color-white);
    border: 1px solid var(--color-black);

    &:disabled {
      color: var(--color-grey-neutral);
      background: transparent;
      border: 1px solid var(--color-grey-neutral);
      cursor: not-allowed;
    }
  }

  &--grey {
    color: var(--color-black);
    background: var(--color-grey-light);
  }

  &--grey-border {
    color: var(--color-grey-neutral);
    background: var(--color-white);
    border: 1px solid var(--color-grey-neutral);
  }

  &--no-border {
    border: none;
  }

  &--transparent {
    background: transparent;
  }
}

</style>
