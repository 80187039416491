import gql      from 'graphql-tag';


export const ARTIST_AGENDA_UPDATE_AVAILABILITIES = gql`
  mutation updateAvailabilities($input: [UpdateAvailabilityInput!]!) {
    updateAvailabilities(input: $input) {
      __typename

      ... on Availabilities {
        availabilities {
          Id
          EventType
          EventDate
        }
      }

      ... on AvailabilityNotFound {
        message
        status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
