import { getInitialState }      from './index.js';


const SET_PENDING_AVAILABILITIES_UPDATES = (state, pendingAvailabilities) => {
	state.pendingAvailabilitiesUpdates = pendingAvailabilities;
};

const SET_AVAILABILITIES = (state, availabilities) => {
  state.availabilities = availabilities;
};

const UPDATE_AVAILABILITIES = (state, availabilities = []) => {
	const updatedAvailabilitiesIDsMap = availabilities.reduce((acc, availability) => ({ ...acc, [availability.Id]: true }), {});

	state.availabilities = [
		...state.availabilities.filter(({ Id }) => !updatedAvailabilitiesIDsMap[Id]),
		...availabilities,
	];
};

const TOGGLE_IS_AVAILABILITIES_LOADING = (state, isLoading) => {
  state.isAvailabilitiesLoading = isLoading;
};

const CLEAR_STATE = (state) => {
  Object.assign(state, getInitialState());
};


export default {
	SET_AVAILABILITIES,
	SET_PENDING_AVAILABILITIES_UPDATES,
	UPDATE_AVAILABILITIES,
	TOGGLE_IS_AVAILABILITIES_LOADING,
	CLEAR_STATE,
};
