import { COMMON_API_RESPONSES }     from "../../../../constants/common-api-responses";


export const EDIT_ARTIST_IDENTITY_RESPONSE = {
  ...COMMON_API_RESPONSES,
  artist: 'Artist',
  // Errors
  SMSError: 'SendingSMSError',
  wrongInput: 'WrongInput',
};
