import store      from '../store/index.js';


const navigationGuard = (to, from, next) => {
  const isArtist = store.getters['User/IS_ARTIST_USER'];
  const isUserAuthenticated = store.state.User.isUserAuthenticated;
  const isAuthenticationRequired = to.matched.some(matchedRoute => matchedRoute.meta.isAuthenticationRequired);
  const isNotAllowedWhenSignedIn = to.matched.some(matchedRoute => matchedRoute.meta.isNotAllowedWhenSignedIn);
  const isClientGoingToArtistOnlyRoute = isUserAuthenticated && !isArtist && to.matched.some(matchedRoute => matchedRoute.meta.isArtistOnly);
  const isAlreadyGoingToSigninOrSignup = ['signin', 'signup'].includes(to.name?.toLowerCase?.());

  const shouldRedirectToSigninOrSignup = isAuthenticationRequired && !isUserAuthenticated && !isAlreadyGoingToSigninOrSignup;
  const shouldNotReaccessEmboarding =
    isUserAuthenticated &&
    to.matched.some(matchedRoute => matchedRoute.meta.stepIndex > -1) &&
    store.getters['User/HAS_COMPLETED_EMBOARDING'];
  const shouldRedirectToEmboarding =
    isUserAuthenticated &&
    !store.getters['User/HAS_COMPLETED_EMBOARDING'] &&
    to.matched.some(matchedRoute => matchedRoute.meta.isEmboardingRequired);
  const previousPage = from.name || (isArtist ? 'ArtistProfile' : 'ClientDashboard');
  const shouldRedirectToPreviousPage = shouldNotReaccessEmboarding || (isNotAllowedWhenSignedIn && isUserAuthenticated);
  const shouldDeleteSearchFilters =  from?.meta?.deleteSearchFiltersIfGoingTo?.includes?.(to.name) ?? false;

  if (shouldDeleteSearchFilters) store.commit('SearchArtist/CLEAR_STATE');
  if (shouldRedirectToEmboarding) return next({ name: store.getters['User/CURRENT_EMBOARDING_STEP'] });
  if (shouldRedirectToSigninOrSignup) store.commit('Routing/SET_PENDING_REDIRECT', to.fullPath);
  if (isClientGoingToArtistOnlyRoute) return next({ name: 'ClientDashboard' });
  if (shouldRedirectToPreviousPage) return next({ name: previousPage });
  if (shouldRedirectToSigninOrSignup) return next({ name: to.meta?.redirect || 'ArtistSignin' });

  next();
};


export default navigationGuard;
