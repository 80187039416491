<template>
  <article class="rgpd-box__wrapper">
    <header class="mb-sm">
      <Heading
        :tag="'h2'"
        :level="'h2'">
        {{ $t('rgpd.title') }}
      </Heading>
    </header>

    <Paragraph class="mb-md">
      {{ $t('rgpd.text') }}
    </Paragraph>
    <Button
      @click="setRGPDAccept(true)"
      type="button">
      {{ $t('common.accept') }}
    </Button>
  </article>
</template>

<script>

import { mapMutations }     from 'vuex';

import Heading              from '../../atoms/Heading/a-Heading.vue';
import Paragraph            from '../../atoms/Paragraph/a-Paragraph.vue';
import Button               from '../../atoms/Button/a-Button.vue';

export default {
  name: 'm-RGPDBox',
  components: {
    Heading,
    Paragraph,
    Button,
  },
  methods: {
    ...mapMutations({
      setRGPDAccept: 'User/SET_RGPD_ACCEPT',
    }),
  },
}

</script>

<style lang="scss">

.rgpd-box {
  &__wrapper {
    z-index: 1000;
    position: fixed;
    bottom: var(--space-sm);
    right: var(--space-sm);
    left: var(--space-sm);
    margin: 0 auto;
    padding: var(--space-md) var(--space-lg);
    border: 1px solid var(--color-grey-light);
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);
    box-shadow: 0 0 10px 5px hsla(0, 0%, 0%, .1);

    @media screen and ($desktop) {
      bottom: var(--space-xl);
      left: var(--space-xl);
      right: 50%;
      max-width: 1200px;
    }
  }
}

</style>
