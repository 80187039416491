import { i18n }             from '../main.js';
import store                from '../store/index.js';
import ACTIVITY_OPTIONS     from '../constants/activity-options.js';


const sortTranslationsAlphabetically = (a, b) => {
  const translationA = i18n.t(a);
  const translationB = i18n.t(b);

  return translationA.localeCompare(translationB, store.state.User.locale.locale, { sensitivity: 'base' });
};

const getActivityTranslation = (activity) => {
  const activityDetails = ACTIVITY_OPTIONS.find(({ value }) => value.toLowerCase() === activity?.toLowerCase());

  return activityDetails?.label ?? '';
};


export {
  sortTranslationsAlphabetically,
  getActivityTranslation,
};
