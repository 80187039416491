import { getInitialState }      from './index.js';


const SET_PENDING_REDIRECT = (state, redirect = {}) => {
	state.pendingRedirect = redirect;
};

const CLEAR_STATE = (state) => {
  Object.assign(state, getInitialState());
};


export default {
	SET_PENDING_REDIRECT,
	CLEAR_STATE,
};
