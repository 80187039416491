<template>
  <div :class="[`toast__wrapper--${type}`, 'toast__wrapper']">
    <div class="toast__header">
      <slot name="header" v-bind="{ message }"></slot>
    </div>
    <div class="toast__content">
      <slot name="content" v-bind="{ message }">
        <p>{{ message }}</p>
      </slot>
    </div>
  </div>
</template>

<script>


import { mapMutations }     from 'vuex';


export default {
  name: 'a-Toast',
  props: {
    type: {
      type: String,
      required: true,
      validator: type => ['alert', 'warning', 'valid', 'primary'].includes(type)
    },
    message: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      default: 5000
    }
  },
  data: () => ({
    timeout: 0,
  }),
  mounted() {
    this.timeout = setTimeout(() => this.clearError({ id: this.id }), this.duration);
  },
  beforeDestroy() {
    this.clearError({ id: this.id });
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapMutations({ clearError: 'CLEAR_ERROR' }),
  },
}

</script>

<style lang="scss">

.toast {
  &__wrapper {
    padding: var(--space-sm);
    border-radius: var(--rounded-sm);
    color: var(--color-white);

    $types: 'valid', 'warning', 'alert';

    @each $type in $types {
      &--#{$type} {
        background-color: var(--color-#{$type});
      }
    }
  }

  &__content {
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);
    font-weight: var(--font-semi);
    line-height: var(--lineheight-xs);
    text-align: center;
    word-break: break-word;
  }
}

</style>