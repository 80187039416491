import { getInitialState }      from './index.js';


const TOGGLE_CLIENT_DASHBOARD_MENU = (state, isOpen = false) => {
  state.isClientDashboardMenuOpen = isOpen;
};

const TOGGLE_LANDING_HERO_VISIBILITY = (state, isVisible = false) => {
  state.isLandingHeroVisible = isVisible;
};

const TOGGLE_MOBILE_FILTERS_TYPE = (state, type) => {
  state.currentMobileFiltersType = type;
};

const CLEAR_STATE = (state) => {
  Object.assign(state, getInitialState());
};


export default {
  TOGGLE_CLIENT_DASHBOARD_MENU,
  TOGGLE_LANDING_HERO_VISIBILITY,
  TOGGLE_MOBILE_FILTERS_TYPE,
  CLEAR_STATE,
};
