import { COMMON_API_RESPONSES }			from "../../../constants/common-api-responses.js";


export const PASSWORD_RESET_RESPONSE = {
  artist: 'Artist',
  client: 'Client',
  // Errors
  wrongInput: 'WrongInput',
  ...COMMON_API_RESPONSES,
};
