<template>
  <component
    :is="tag"
    :class="[headingStyle, 'heading']">
    <slot></slot>
  </component>
</template>

<script>
import Icon                         from "../Icon/a-Icon";

export default {
  name: 'a-Heading',
  components: {
    Icon
  },
  props: {
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'h1',
      validator: tag => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'span', 'label', 'li', 'button'].includes(tag)
    },
    level: {
      type: String,
      default: 'h1',
      validator: tag => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tag)
    },
    isTextCentered: {
      type: Boolean,
      default: false,
    },
    isCapitalized: {
      type: Boolean,
      default: false,
    },
    isUppercased: {
      type: Boolean,
      default: false,
    },
    isUnderlined: {
      type: Boolean,
      default: false,
    },
    isOverflow: {
      type: Boolean,
      default: false,
    },
     color: {
      type: String,
      default: 'black',
      validator: color => ['gradient', 'black', 'white', 'grey', 'light'].includes(color)
    },
  },
  computed: {
    headingStyle() {
      return {
        [`heading--${this.level}`]: true,
        [`heading--${this.color}`]: this.color,
        'heading--title': this.level === 'title',
        'heading--subtitle': this.level === 'subtitle',
        'heading--category': this.level === 'category',
        'heading--centered': this.isTextCentered,
        'heading--capitalized': this.isCapitalized,
        'heading--uppercased': this.isUppercased,
        'heading--underlined': this.isUnderlined,
        'heading--overflow': this.isOverflow,

      }
    }
  }
}

</script>

<style lang="scss">

.heading {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-black);

  &__icon {
    @media screen and ($mobile-xs) {
      margin-top: var(--space-xs);
    }
  }

  $heading-font: (
    "h1": var(--font-stack-primary),
    "h2": var(--font-stack-primary),
    "h3": var(--font-stack-primary),
    "h4": var(--font-stack-secondary),
    "h5": var(--font-stack-secondary),
  );

  $heading-font-size: (
    "h1": var(--text-xxl),
    "h2": var(--text-xl),
    "h3": var(--text-md),
    "h4": var(--text-base),
    "h5": var(--text-xs),
  );

  $heading-lineheight: (
    "h1": var(--lineheight-xxl),
    "h2": var(--lineheight-xl),
    "h3": var(--lineheight-lg),
    "h4": var(--lineheight-base),
    "h5": var(--lineheight-sm),
  );

  $heading-font-weight: (
    "h1": var(--font-bold),
    "h2": var(--font-medium),
    "h3": var(--font-medium),
    "h4": var(--font-bold),
    "h5": var(--font-bold),
  );

  $heading-colors: (
    "black": var(--color-black),
    "white": var(--color-white),
    "grey": var(--color-grey-semi),
    "light": var(--color-grey-light),
    "gradient": var(--color-gradient-main),
  );

  @each $color-key, $color-value in $heading-colors {
    &--#{$color-key} {
      @if $color-key == "gradient" {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: $color-value;
      } @else {
        color: $color-value;
      }
    }
  }

  @each $font-key, $font-value in $heading-font {
    &--#{$font-key} {
      font-family: $font-value;
    }
  }

  @each $font-size-key, $font-size-value in $heading-font-size {
    &--#{$font-size-key} {
      font-size: $font-size-value;
    }
  }

  @each $lineheight-key, $lineheight-value in $heading-lineheight {
    &--#{$lineheight-key} {
      line-height: $lineheight-value;
    }
  }

  @each $font-weight-key, $font-weight-value in $heading-font-weight {
    &--#{$font-weight-key} {
      font-weight: $font-weight-value;
    }
  }

  &--centered {
    justify-content: center;
    text-align: center;
  }

  &--capitalized {
    text-transform: capitalize;
  }

  &--uppercased {
    text-transform: uppercase;
  }

  &--underlined {
    text-decoration: underline;
    text-underline-offset: 1px;
  }

  &--overflow {
    @include textOverflowEllipsis();
  }

  $colors: (
    "primary": var(--color-primary),
    "light": var(--color-grey-light),
    "white": var(--color-white),
    "neutral": var(--color-grey-neutral),
    "dark": var(--color-grey-dark),
    "black": var(--color-black),
    "valid": var(--color-valid),
    "warning": var(--color-warning),
    "alert": var(--color-alert),
  );

  @each $color-key, $color-value in $colors {
    &--#{$color-key} {
      color: $color-value;
    }
  }
}
</style>
