const getDateWithTimezoneOffset = (date = new Date(Date.now())) => {
  const timezoneOffsetInHours = Math.abs(date.getTimezoneOffset() / 60);
  const timezoneOffsetInMilliseconds = 60 * 60 * 1000 * timezoneOffsetInHours;
  const dateWithTimezoneOffsetTimestamp = (Date.parse(date) + timezoneOffsetInMilliseconds) / 1000;

  return dateWithTimezoneOffsetTimestamp;
};

const getDateFromDDMMYYYYToMMDDYYYY = (dateAsString) => {
  const [ day, month, year ] = dateAsString.replaceAll('-', '/').split('/');

  return `${month}/${day}/${year}`;
};

const getDateAsDDMMYYYY = (date = new Date(Date.now())) => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const getDateAsYYYYMMDD = (date = new Date(Date.now())) => {
  const dateWithTimezoneOffset = new Date(getDateWithTimezoneOffset(new Date(date)) * 1000);

  return dateWithTimezoneOffset.toISOString().substring(0, 10);
};

const isCurrentOrFutureDate = (dateInMilliseconds) => {
  const [currentDate, targetDate] = [new Date(), new Date(dateInMilliseconds)];
  const [currentDay, currentMonth, currentYear] = [currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear()];
  const [targetDay, targetMonth, targetYear] = [targetDate.getDate(), targetDate.getMonth() + 1, targetDate.getFullYear()];
  const isCurrentOrFutureDate =
    new Date(`${targetYear}/${targetMonth}/${targetDay}`) >=
    new Date(`${currentYear}/${currentMonth}/${currentDay}`);

  return isCurrentOrFutureDate;
};

const isCurrentOrFutureDatetime = (dateInMilliseconds) => {
  const [currentDate, targetDate] = [new Date(), new Date(dateInMilliseconds)];
  const [
    currentDay,
    currentMonth,
    currentYear,
    currentHour,
    currentMinute,
  ] = [currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear(), currentDate.getHours(), currentDate.getMinutes()];
  const [
    targetDay,
    targetMonth,
    targetYear,
    targetHour,
    targetMinute,
  ] = [targetDate.getDate(), targetDate.getMonth() + 1, targetDate.getFullYear(), targetDate.getHours(), targetDate.getMinutes()];
  const currentTimestamp = getDateWithTimezoneOffset(new Date(`${currentYear}/${currentMonth}/${currentDay} ${currentHour}:${currentMinute}`));
  const targetTimestamp = getDateWithTimezoneOffset(new Date(`${targetYear}/${targetMonth}/${targetDay} ${targetHour}:${targetMinute}`));

  return Boolean((targetTimestamp - currentTimestamp) > 0);
};

const getDateAsLocalizedString = ({
    date = new Date(Date.now()),
    locale = 'fr',
    options = {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    },
  }) => {

  return date.toLocaleDateString(locale, options);
};

const getTimeAsMMSS = (valueInSeconds) => {
  const secondsNum = parseInt(valueInSeconds, 10);
  let minutes = Math.floor((secondsNum) / 60);
  let seconds = secondsNum - (minutes * 60);

  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${minutes}:${seconds}`;
};

const isValidBirthday = ({ day, month, year }) => {
  const birthday = new Date(`${month}/${day}/${year}`);
  const isValidBirthday = !Number.isNaN(Date.parse(birthday))
    && birthday.getDate() === Number(day)
    && birthday.getMonth() + 1 === Number(month)
    && birthday.getFullYear() === Number(year);

  return isValidBirthday;
};

const addHoursToTime = ({ locale = 'fr', date, startTime, hours }) => {
  const [ startHour, startMinute ] = startTime.split(':').map((time => Number(time)));

  date.setHours(startHour + (hours > 1 ? hours : 0));
  date.setMinutes(startMinute + (hours > 1 ? 0 : (60 * hours)));

  return date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', });
};


export {
  getDateWithTimezoneOffset,
  getDateAsDDMMYYYY,
  getDateAsYYYYMMDD,
  getDateFromDDMMYYYYToMMDDYYYY,
  isCurrentOrFutureDate,
  isCurrentOrFutureDatetime,
  getDateAsLocalizedString,
  getTimeAsMMSS,
  isValidBirthday,
  addHoursToTime,
};
