import gql      from 'graphql-tag';


export const ARTIST_PUBLIC_PROFILE_MINIATURE = gql`
  query artist($id: String!) {
    artist(id: $id) {
      __typename

      ... on PublicArtist {
        __typename
        ID
        Description
        ArtisteNicknameGenerated
        URLProfilePictures
        ActivityInformations {
            __typename
        }
      }
      ... on UserNotFound {
        message
        status
      }
    }
  }
`;
