import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses';


export const ARTIST_AGENDA_UPDATE_AVAILABILITIES_RESPONSE = {
  // Success
  availabilities: 'Availabilities',
  // Managed Failures
  availabilityNotFound: 'AvailabilityNotFound',
  unauthorized: 'Unauthorized',
  ...COMMON_API_RESPONSES,
};
