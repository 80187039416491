import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses';


export const PUBLISH_ARTIST_PROFILE_RESPONSE = {
  artist: 'Artist',
  algoliaError: 'AlgoliaError',
  pricesShouldBeSet: 'PricesShouldBeSet',
  stripeIsNotSetup: 'StripeIsNotSetup',
  ...COMMON_API_RESPONSES,
};
