import { apolloProvider }                                 	from '../../graphql/config.js';
import { i18n }                                           	from '../../main.js';
import { ARTIST_AVAILABILITIES }                          	from '../../features/ArtistAgenda/api/queries/q-ArtistAvailabilities.js';
import { ARTIST_AVAILABILITIES_RESPONSE }                 	from '../../features/ArtistAgenda/api/responses/r-ArtistAvailabilities.js';
import { ARTIST_AGENDA_UPDATE_AVAILABILITIES }              from '../../features/ArtistAgenda/api/queries/q-ArtistAgendaUpdateAvailabilities.js';
import { ARTIST_AGENDA_UPDATE_AVAILABILITIES_RESPONSE }			from '../../features/ArtistAgenda/api/responses/r-ArtistAgendaUpdateAvailabilities.js';


const FETCH_AVAILABILITIES = async ({ commit }, date) => {
	try {
		commit('TOGGLE_IS_AVAILABILITIES_LOADING', true);

		const data = await apolloProvider.defaultClient.query({
			query: ARTIST_AVAILABILITIES,
			variables: { date },
			fetchPolicy: 'network-only'
		});
		const { '__typename': responseTypeName, ...availabilitiesData } = data.data.availabilities;

		switch (responseTypeName) {
			case ARTIST_AVAILABILITIES_RESPONSE.availabilities:
				commit('SET_AVAILABILITIES', availabilitiesData.availabilities);
				break;

			case ARTIST_AVAILABILITIES_RESPONSE.availabilityNotFound:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('artist.dashboard.errors.dateNotFound') }], { root: true });
				break;

			case ARTIST_AVAILABILITIES_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
		commit('TOGGLE_IS_AVAILABILITIES_LOADING', false);
	}
};


const EDIT_AVAILABILITIES = async ({ state, commit }) => {
  try {
		commit('TOGGLE_IS_AVAILABILITIES_LOADING', true);

		const input = { input: state.pendingAvailabilitiesUpdates.map(({ Id, EventType }) => ({ Id, EventType })) };
		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_AGENDA_UPDATE_AVAILABILITIES,
			variables: input,
		});
		const { '__typename': responseTypeName, ...availabilitiesUpdateResult } = data.data.updateAvailabilities;

		switch (responseTypeName) {
			case ARTIST_AGENDA_UPDATE_AVAILABILITIES_RESPONSE.availabilities:
				commit('UPDATE_AVAILABILITIES', availabilitiesUpdateResult.availabilities);
				commit('SET_PENDING_AVAILABILITIES_UPDATES', []);
				break;

			case ARTIST_AGENDA_UPDATE_AVAILABILITIES_RESPONSE.availabilityNotFound:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('artist.dashboard.errors.dateNotFound') }], { root: true });
				break;

			case ARTIST_AGENDA_UPDATE_AVAILABILITIES_RESPONSE.unknownError:
			case ARTIST_AGENDA_UPDATE_AVAILABILITIES_RESPONSE.unauthorized:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.${responseTypeName}') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
		commit('TOGGLE_IS_AVAILABILITIES_LOADING', false);
	}
};


export default {
	FETCH_AVAILABILITIES,
	EDIT_AVAILABILITIES,
};