import gql      from 'graphql-tag';


export const GET_INVOICE_QUERY = gql`
  query getInvoice($bookingID: String!) {
    getInvoice(bookingID: $bookingID) {
      ... on InvoiceURL {
        URL
      }

      ... on BookingNotFound {
        message
        status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
