import gql      from 'graphql-tag';

// TODO
// HasCapacityEquipment
export const ARTIST_PUBLIC_PROFILE = gql`
  query artist($id: String!) {
    artist(id: $id) {
      __typename

      ... on PublicArtist {
        __typename
        ID
        Description
        Firstname
        ArtisteNickname
        ArtisteNicknameGenerated
        URLProfilePictures
        URLBannerPictures
        ProfilePicturesCropsOptions
        BannerPicturesCropsOptions
        Activity
        Gender
        PerformanceDuration
        LocationRange
        Languages
        City
        Tags
        ClientPrice {
          Exception
          Normal
        }
        Price {
          Exception
          Normal
        }
        Medias {
          ... on Media {
            ID
            URL
            Type
            Options
            Rank
          }
        }
        FAQ {
          ... on QandA {
            ID
            QuestionTag
            Answer
          }
        }
        Availabilities {
          Id
          EventType
          EventDate
        }
        ChildrenPermission
        ActivityInformations {
          ... on Animator {
            NumberOfClientSupported
          }

          ... on Band {
            NumberOfMembers
          }

          ... on CircusArtist {
            NumberOfTricks
          }

          ... on Dancer {
            NumberOfMembers
          }

          ... on Doppleganger {
            NumberOfCostumes
          }

          ... on Hypnotiser {
            NumberOfTricks
          }

          ... on Magician {
            NumberOfTricks
          }

          ... on MakeupArtist {
            NumberOfClientSupported
          }

          ... on Mentalist {
            NumberOfTricks
          }

          ... on Puppeteer {
            NumberOfTricks
          }
        }
      }

      ... on UserNotFound {
        message
        status
      }
    }
  }
`;
