<template>
  <component
    :is="tag"
    :class="[paragraphStyle, 'paragraph']">
    <slot></slot>
  </component>
</template>

<script>

export default {
  name: 'a-Paragraph',
  props: {
    tag: {
      type: String,
      default: 'p',
      validator: (tag) => ['a', 'figcaption', 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'dt', 'dd', 'span', 'li', 'button'].includes(tag),
    },
    size: {
      type: String,
      default: 'base',
      validator: (size) => ['sm', 'base', 'md'].includes(size),
    },
    color: {
      type: String,
      default: '',
      validator: (color) => ['', 'valid', 'alert', 'warning', 'dark', 'black', 'white', 'grey'].includes(color),
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    isTextCentered: {
      type: Boolean,
      default: false,
    },
    isCapitalized: {
      type: Boolean,
      default: false,
    },
    isOverflow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    paragraphStyle() {
      return {
        [`paragraph--${this.size}`]: true,
        [`paragraph--bold`]: this.isBold,
        [`paragraph--${this.color}`]: this.color,
        'paragraph--centered': this.isTextCentered,
        'paragraph--capitalized': this.isCapitalized,
        'paragraph--overflow': this.isOverflow,
      };
    },
  },
};

</script>

<style lang="scss">

.paragraph {
  font-family: var(--font-stack-secondary);
  color: var(--color-black);

  &--sm {
    font-size: var(--text-xxs);
    line-height: var(--lineheight-xs);
    font-style: italic;
    color: var(--color-grey-neutral);
  }

  &--base {
    font-size: var(--text-sm);
    line-height: var(--lineheight-md);
    font-weight: var(--font-light);
  }

  &--md {
    font-size: var(--text-base);
  }

  &--bold {
    font-weight: var(--font-bold);
  }

  &--centered {
    justify-content: center;
    text-align: center;
  }

  &--capitalized {
    text-transform: capitalize;
  }

  &--overflow {
    @include textOverflowEllipsis();
  }

  $colors: (
    "primary": var(--color-primary),
    "light": var(--color-grey-light),
    "white": var(--color-white),
    "neutral": var(--color-grey-neutral),
    "dark": var(--color-grey-dark),
    "black": var(--color-black),
    "valid": var(--color-valid),
    "grey": var(--color-grey-semi),
    "warning": var(--color-warning),
    "alert": var(--color-alert),
    "contrast": var(--color-grey-contrasted),
  );

  @each $color-key, $color-value in $colors {
    &--#{$color-key} {
      color: $color-value;
    }
  }
}
</style>
