import Vue                      from 'vue';
import Vuex                     from 'vuex';
import createPersistedState     from 'vuex-persistedstate';

import rootMutations            from './Root/mutations.js';
import rootActions              from './Root/actions.js';
import rootGetters              from './Root/getters.js';
import Layout                   from './Layout/index.js';
import User                     from './User/index.js';
import Availabilities           from './Availabilities/index.js';
import SearchArtist             from './SearchArtist/index.js';
import Stripe                   from './Stripe/index.js';
import Routing                  from './Routing/index.js';
import Landing                  from './Landing/index.js';
import {
  isNewAppVersionAvailable,
  clearVuexLocalStorage
}                               from '../utils/localStorageUtils.js';


if (isNewAppVersionAvailable()) clearVuexLocalStorage();

Vue.use(Vuex);

const unpersistedMutations = ['ADD_ERRORS'];
const getInitialState = () => ({
  errors: [],
});
const store = new Vuex.Store({
  strict: true,
  state: getInitialState,
  getters: rootGetters,
  mutations: rootMutations,
  actions: rootActions,
  modules: {
    Layout,
    User,
    Availabilities,
    SearchArtist,
    Stripe,
    Routing,
    Landing,
  },
  plugins: [createPersistedState({
    key: process.env.VUE_APP_VERSION ?? 'vuex',
    filter: (mutation) => !unpersistedMutations.includes(mutation.type),
  })],
});


export { getInitialState };
export default store;
