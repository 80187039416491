import gql      from 'graphql-tag';


export const ARTIST_EDIT_FAQ = gql`
  mutation editFAQ($input: [QandAInput]!) {
    editFAQ(input: $input) {
      ... on FAQ {
        QandA {
          ID
          QuestionTag
          Answer
        }
      }

      ... on FAQOwnerShip {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
