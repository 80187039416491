import ACTIVITIES 							from './activities';
import AnimationImage           from '../../public/assets/images/landing/animation.jpg';
import BandImage                from '../../public/assets/images/landing/groupe.jpg';
import CircusImage              from '../../public/assets/images/landing/cirque.jpg';
import DancerImage              from '../../public/assets/images/landing/danse.jpg';
import DopplegangerImage				from '../../public/assets/images/landing/personnage.jpg';
import DjImage                  from '../../public/assets/images/landing/dj.jpg';
import HypnotiserImage          from '../../public/assets/images/landing/hypnose.jpg';
import MagicianImage            from '../../public/assets/images/landing/magie.jpg';
import MakeupImage              from '../../public/assets/images/landing/makeup.jpg';
import MentalistImage           from '../../public/assets/images/landing/mentaliste.jpg';
import MusicianImage            from '../../public/assets/images/landing/musicien.jpg';
import PuppeteerImage           from '../../public/assets/images/landing/marionnettes.jpg';
import SingerImage              from '../../public/assets/images/landing/chant.jpg';
import SingerMusicianImage			from '../../public/assets/images/landing/musicienchanteur.jpg';


export default [
	{
		label: 'activities.Animator',
		value: ACTIVITIES.animator,
		image: AnimationImage,
	},
	{
		label: 'activities.MakeupArtist',
		value: ACTIVITIES.makeupArtist,
		image: MakeupImage,
	},
	{
		label: 'activities.CircusArtist',
		value: ACTIVITIES.circusArtist,
		image: CircusImage,
	},
	{
		label: 'activities.Singer',
		value: ACTIVITIES.singer,
		image: SingerImage,
	},
	{
		label: 'activities.SingerMusician',
		value: ACTIVITIES.singerMusicien,
		image: SingerMusicianImage,
	},
	{
		label: 'activities.Dancer',
		value: ACTIVITIES.dancer,
		image: DancerImage,
	},
	{
		label: 'activities.Dj',
		value: ACTIVITIES.dj,
		image: DjImage,
	},
	{
		label: 'activities.Band',
		value: ACTIVITIES.band,
		image: BandImage,
	},
	{
		label: 'activities.Hypnotiser',
		value: ACTIVITIES.hypnotiser,
		image: HypnotiserImage,
	},
	{
		label: 'activities.Magician',
		value: ACTIVITIES.magician,
		image: MagicianImage,
	},
	{
		label: 'activities.Musician',
		value: ACTIVITIES.musicien,
		image: MusicianImage,
	},
	{
		label: 'activities.Mentalist',
		value: ACTIVITIES.mentalist,
		image: MentalistImage,
	},
	{
		label: 'activities.Doppleganger',
		value: ACTIVITIES.doppleganger,
		image: DopplegangerImage,
	},
	{
		label: 'activities.Puppeteer',
		value: ACTIVITIES.puppeteer,
		image: PuppeteerImage,
	},
];
