export const ARTIST_AVAILABILITIES_RESPONSE = {
  // Success
  availabilities: 'Availabilities',
  // Managed Failures
  availabilityNotFound: 'AvailabilityNotFound',
  noTokenFound: 'NoTokenFound',
  expiredToken: 'ExpiredToken',
  wrongToken: 'WrongToken',
  // Errors
  unknownError: 'UnknownError'
};
