import { apolloProvider }     from '../../graphql/config.js';
import { i18n }               from '../../main.js';
import GET_LANDING_QUERY      from '../../features/Landing/api/queries/q-getLanding.js';


const GET_LANDING = async ({ commit }) => {
  try {
		const data = await apolloProvider.defaultClient.query({
			query: GET_LANDING_QUERY,
    });
    const {
      __typename: responseTypeName, // eslint-disable-line
      ...landingData
    } = data.data.getLanding;

    commit('SET_LANDING_DATA', landingData);
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};


export default {
  GET_LANDING
};
