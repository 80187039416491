import gql from 'graphql-tag';


export const USER_ME = gql`
 query me {
  me {
    __typename
    ... on Artist {
      ID
      Token
      Email
      Firstname
      Lastname
      PhoneNumber
      Birthday
      BirthdayLocation
      createdAt
      LocationRange
      Line1
      Line2
      PostalCode
      State
      City
      Country
      Description
      URLCardID
      IsCertified
      HasCapacityEquipment
      ArtisteNickname
      ArtisteNicknameGenerated
      URLProfilePictures
      URLBannerPictures
      ProfilePicturesCropsOptions
      BannerPicturesCropsOptions
      IsAdditionalHourOk
      Tags
      Languages
      IsBanned
      StatusName
      Status {
        __typename
        ... on Company {
          Siret
          VATNumber
          KBISUrl
          VATTaxable
          IsRCPro
          Name
        }
        ... on Intermittent {
          Nationality
          FamilyStatus
          PlaceOfBirth
          StateOfBirth
          CountryOfBirth
          SocialSecurityNumber
          ShowLeaveNumber
          Iban
          Bic
          TaxRate
        }
      }
      FAQ {
        ID
        QuestionTag
        Answer
      }
      Medias {
        ID
        URL
        Type
        Options
        Rank
      }
      PerformanceDuration
      ClientPrices {
        Normal
        Exception
      }
      DefaultPrices {
        __typename
        Normal
        Exception
      }
      Price {
        __typename
        Normal
        Exception
      }
      Bookings {
        ID
        EventType
        EventDate
        PriceForClient
        PriceForArtist
        CanceledBeforeDueDate
        Line1
        Line2
        PostalCode
        City
        Country
        StartTime
        EndTime
        Status
        IsMaterialNeeded
        IsCanceledByClient
        NumberOfPeople
        Client {
          Email
          Firstname
          Lastname
          PhoneNumber
        }
        Artist {
          ID
          Email
          Firstname
          Lastname
          ArtisteNicknameGenerated
          PhoneNumber
          URLProfilePictures
          ProfilePicturesCropsOptions
        }
      }
      IsActive
      IsEmailValid
      IsPhoneNumberConfirmed
      IsStripeSetup
      ChildrenPermission
      createdAt
      Gender
      Latitude
      Longitude
      Activity
      ActivityInformations{
        __typename
        ... on Animator {
          NumberOfClientSupported
        }
        ... on Band {
          NumberOfMembers
        }
        ... on CircusArtist {
          NumberOfTricks
        }
        ... on Dancer {
          NumberOfMembers
        }
        ... on Doppleganger {
          NumberOfCostumes
        }
        ... on Hypnotiser {
          NumberOfTricks
        }
        ... on Magician {
          NumberOfTricks
        }
        ... on MakeupArtist {
          NumberOfClientSupported
        }
        ... on Mentalist {
          NumberOfTricks
        }
        ... on Puppeteer {
          NumberOfTricks
        }
      }
    }

    ... on Client {
      ID
      Token
      Email
      Bookings {
        ID
        EventType
        EventDate
        PriceForClient
        PriceForArtist
        CanceledBeforeDueDate
        Line1
        Line2
        PostalCode
        City
        Country
        StartTime
        EndTime
        Status
        IsMaterialNeeded
        IsCanceledByClient
        NumberOfPeople
        ClientEventType
        Client {
          Email
          Firstname
          Lastname
          PhoneNumber
        }
        Artist {
          ID
          Email
          Firstname
          Lastname
          ArtisteNicknameGenerated
          PhoneNumber
          URLProfilePictures
          ProfilePicturesCropsOptions
        }
      }
      Firstname
      Lastname
      PhoneNumber
      Birthday
      Gender
      Line1
      Line2
      PostalCode
      City
      Country
    }
    ... on TokenError {
      status
      message
      requestID
    }
  }
}

`;
