import gql      from 'graphql-tag';


export const PASSWORD_RESET = gql`
  mutation resetPassword($password: String!) {
    resetPassword(password: $password) {
      __typename

      ... on Artist {
        ID
      }

      ... on Client {
        ID
      }

      ... on WrongInput {
        errors {
          message
          status
        }
      }

      ... on TokenError {
        message
        status
      }
    }
  }
`;
