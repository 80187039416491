import gql      from 'graphql-tag';


export const ARTIST_UPDATE_MEDIA_RANKING = gql`
  mutation updateMediaRank($input: [UpdateMediaRankInput]) {
    updateMediaRank(input: $input) {
      __typename

      ... on Artist {
        Medias {
          ID
          URL
          Type
          Options
          Rank
        }
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
