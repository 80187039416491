import actions 				from './actions.js';
import getters 				from './getters.js';
import mutations			from './mutations.js';


const getInitialState = () => ({
	pendingRedirect: null,
});


export { getInitialState };
export default {
	namespaced: true,
	state: getInitialState(),
	actions,
	getters,
	mutations,
};
