import gql      from 'graphql-tag';


export const ARTIST_VERIFY_PHONE_NUMBER_CODE = gql`
  mutation verifyPhonenumberCode($input: verifyPhonenumberCodeInput!) {
    verifyPhonenumberCode(input: $input) {
      ... on Artist {
        IsPhoneNumberConfirmed
        PhoneNumber
      }

      ... on Client {
        PhoneNumber
      }

      ... on NoSMSCodeFound {
        message
        status
      }

      ... on WrongSMSCode {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
