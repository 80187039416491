import { GET_ACTIVITY } 																									 			from '../../features/EditArtist/api/queries/q-get-activity.js';
import { EDIT_ACTIVITY as EDIT_ACTIVITY_QUERY } 													 			from '../../features/EditArtist/api/queries/q-edit-activity.js';
import { EDIT_ACTIVITY_RESPONSE } 																				 			from '../../features/EditArtist/api/responses/r-edit-activity.js';
import { EDIT_ARTIST as EDIT_ARTIST_IDENTITY_QUERY } 														from '../../features/EditArtist/api/queries/q-edit-artist.js';
import { EDIT_ARTIST_IDENTITY_RESPONSE } 																				from '../../features/EditArtist/api/responses/r-edit-artist.js';
import { EDIT_CLIENT as EDIT_CLIENT_IDENTITY_QUERY } 														from '../../features/EditClient/api/queries/q-edit-client.js';
import { EDIT_CLIENT_IDENTITY_RESPONSE } 																				from '../../features/EditClient/api/responses/r-edit-client.js';
import { ARTIST_PROFILE_UPLOAD_PROFILE_PICTURE } 													 			from '../../features/ArtistProfile/api/queries/q-ArtistProfileUploadProfilePicture.js';
import { ARTIST_PROFILE_UPLOAD_PROFILE_PICTURE_RESPONSE }									 			from '../../features/ArtistProfile/api/responses/r-ArtistProfileUploadProfilePicture.js';
import { ARTIST_PROFILE_UPLOAD_BANNER_PICTURE }														 			from '../../features/ArtistProfile/api/queries/q-ArtistProfileUploadProfileBanner.js';
import { ARTIST_PROFILE_UPLOAD_BANNER_PICTURE_RESPONSE } 									 			from '../../features/ArtistProfile/api/responses/r-ArtistProfileUploadProfileBanner.js';
import { CLOUDINARY_VIDEO_UPLOAD_NOTIFICATION }														 			from '../../features/ArtistProfile/api/queries/s-cloudinary-video-upload.js';
import { ARTIST_UPLOAD_PRIVATE_DOCUMENT } 																 			from '../../features/EditArtist/api/queries/q-upload-private-document.js';
import { ARTIST_UPLOAD_PRIVATE_DOCUMENT_RESPONSE } 												 			from '../../features/EditArtist/api/responses/r-upload-private-document.js';
import { ARTIST_EDIT_FAQ }              																	 			from '../../features/EditArtist/api/queries/q-edit-faq.js';
import { ARTIST_EDIT_FAQ_RESPONSE }     																	 			from '../../features/EditArtist/api/responses/r-edit-faq.js';
import { ARTIST_UPLOAD_MEDIA }																						 			from '../../features/EditArtist/api/queries/q-uploadMedia.js';
import { ARTIST_UPLOAD_MEDIA_RESPONSE }																		 			from '../../features/EditArtist/api/responses/r-uploadMedia.js';
import { ARTIST_UPDATE_MEDIA_RANKING as ARTIST_UPDATE_MEDIA_RANKING_QUERY }			from '../../features/ArtistProfile/api/queries/q-artist-update-media-ranking.js';
import { ARTIST_UPDATE_MEDIA_RANKING_RESPONSE }																	from '../../features/ArtistProfile/api/responses/r-artist-update-media-ranking.js';
import { ARTIST_VERIFY_PHONE_NUMBER_CODE }																			from '../../features/Emboarding/api/queries/q-VerifyPhoneNumberCode.js';
import { ARTIST_VERIFY_PHONE_NUMBER_CODE_RESPONSE }															from '../../features/Emboarding/api/responses/r-VerifyPhoneNumberCode.js';
import { PASSWORD_RECOVERY }																										from '../../graphql/shared/queries/q-PasswordRecovery.js';
import { PASSWORD_RECOVERY_RESPONSE }																						from '../../graphql/shared/responses/r-PasswordRecovery.js';
import { PUBLISH_ARTIST_PROFILE }																								from '../../features/EditArtist/api/queries/q-publish-artist-profile.js';
import { PUBLISH_ARTIST_PROFILE_RESPONSE }																			from '../../features/EditArtist/api/responses/r-publish-artist-profile.js';
import { UNPUBLISH_ARTIST_PROFILE }																							from '../../features/EditArtist/api/queries/q-unpublish-artist-profile.js';
import { UNPUBLISH_ARTIST_PROFILE_RESPONSE }																		from '../../features/EditArtist/api/responses/r-unpublish-artist-profile.js';
import { ARTIST_DELETE_MEDIA }																									from '../../features/EditArtist/api/queries/q-deleteMedia.js';
import { ARTIST_DELETE_MEDIA_RESPONSE }																					from '../../features/EditArtist/api/responses/r-deleteMedia.js';
import { EDIT_STATUS_INFORMATIONS as EDIT_STATUS_INFORMATIONS_QUERY }						from '../../features/ArtistPayments/api/queries/q-update-status-informations.js';
import { EDIT_STATUS_INFORMATIONS_RESPONSE }																		from '../../features/ArtistPayments/api/responses/r-update-status-informations.js';
import { EMAIL_VALIDATE_SUBSCRIPTION }																					from '../../features/Signup/api/subscriptions/s-emailValidate.js';
import { USER_ME } 																															from '../../graphql/shared/queries/q-UserMe.js';
import { USER_ME_RESPONSE } 																										from '../../graphql/shared/responses/r-UserMe.js';
import { GET_BOOKINGS as GET_BOOKINGS_QUERY }																		from '../../graphql/shared/queries/q-GetBookings.js';
import { PASSWORD_RESET } 																											from '../../graphql/shared/queries/q-PasswordReset.js';
import { PASSWORD_RESET_RESPONSE } 																							from '../../graphql/shared/responses/r-PasswordReset.js';
import { apolloProvider, wsLink } 																							from '../../graphql/config.js';
import { i18n } 																																from '../../main.js';
import router																																		from '../../router/router.js';


const FETCH_ME = async ({ commit }) => {
	commit('TOGGLE_IS_USER_LOADING', true);

	try {
		const data = await apolloProvider.defaultClient.query({
			query: USER_ME,
			fetchPolicy: 'network-only'
		});
		const { '__typename': responseTypeName, ...userData } = data.data.me;

		switch (responseTypeName) {
			case USER_ME_RESPONSE.artist:
			case USER_ME_RESPONSE.client:
				commit('SET_USER', userData);
				break;

			case USER_ME_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
		commit('TOGGLE_IS_USER_LOADING', false);
	}
};

const GET_BOOKINGS = async ({ commit }) => {
	commit('TOGGLE_IS_USER_LOADING', true);

	try {
		const data = await apolloProvider.defaultClient.query({
			query: GET_BOOKINGS_QUERY,
			fetchPolicy: 'network-only'
		});
		const { '__typename': responseTypeName, ...userData } = data.data.me;

		switch (responseTypeName) {
			case USER_ME_RESPONSE.artist:
			case USER_ME_RESPONSE.client:
				commit('SET_USER', userData);
				break;

			case USER_ME_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
		commit('TOGGLE_IS_USER_LOADING', false);
	}
};

const FETCH_ACTIVITY = async ({ commit, state }) => {
	if (state.user.ActivityInformations.__typename !== 'NotFetched') return;

	try {
		const data = await apolloProvider.defaultClient.query({
			query: GET_ACTIVITY,
		});
		const activity = data.data.activity;

		switch (activity.__typename) {
			case EDIT_ACTIVITY_RESPONSE.notSet:
			case EDIT_ACTIVITY_RESPONSE.musicien:
			case EDIT_ACTIVITY_RESPONSE.band:
			case EDIT_ACTIVITY_RESPONSE.circusArtist:
			case EDIT_ACTIVITY_RESPONSE.dancer:
			case EDIT_ACTIVITY_RESPONSE.dj:
			case EDIT_ACTIVITY_RESPONSE.doppleganger:
			case EDIT_ACTIVITY_RESPONSE.hypnotiser:
			case EDIT_ACTIVITY_RESPONSE.magician:
			case EDIT_ACTIVITY_RESPONSE.makeupArtist:
			case EDIT_ACTIVITY_RESPONSE.mentalist:
			case EDIT_ACTIVITY_RESPONSE.puppeteer:
			case EDIT_ACTIVITY_RESPONSE.singer:
			case EDIT_ACTIVITY_RESPONSE.singerMusicien:
				commit('SET_USER', activity);
				break;

			case EDIT_ACTIVITY_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const EDIT_ACTIVITY = async ({ commit }, params) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: EDIT_ACTIVITY_QUERY,
			variables: params
		});
		const { __typename: responseTypeName, ...activityData } = data.data.updateActivity;

		switch (responseTypeName) {
			case EDIT_ACTIVITY_RESPONSE.artist:
				commit('SET_USER', activityData);
				break;

			case EDIT_ACTIVITY_RESPONSE.onlyOneActivity:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('artist.editActivity.errors.onlyOneActivity') }], { root: true });
				break;

			case EDIT_ACTIVITY_RESPONSE.tokenError:
			default:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const EDIT_IDENTITY = async ({ getters, commit }, params) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: getters.IS_ARTIST_USER
				? EDIT_ARTIST_IDENTITY_QUERY
				: EDIT_CLIENT_IDENTITY_QUERY,
			variables: params,
		});
		const { __typename: responseTypeName, ...artistIdentityData } = data.data[getters.IS_ARTIST_USER ? 'updateArtist' : 'updateClient'];

		switch (responseTypeName) {
			case EDIT_ARTIST_IDENTITY_RESPONSE.artist:
			case EDIT_CLIENT_IDENTITY_RESPONSE.client:
				commit('SET_USER', artistIdentityData);
				return true;

			case EDIT_CLIENT_IDENTITY_RESPONSE.emailNotValidate:
				router.push({ name: 'ClientMailValidation' });
				return false;

			case EDIT_ARTIST_IDENTITY_RESPONSE.wrongInput:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('artist.editIdentity.errors.WrongInput') }], { root: true });
				return false;

			case EDIT_ARTIST_IDENTITY_RESPONSE.SMSError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('artist.editIdentity.errors.SMSError') }], { root: true });
				return false;

			case EDIT_ACTIVITY_RESPONSE.unknownError:
			default:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				return false;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const UPLOAD_PROFILE_PICTURE = async ({ commit }, params) => {
	try {
		commit('TOGGLE_IS_LOADING_PROFILE_PICTURE', true);

		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_PROFILE_UPLOAD_PROFILE_PICTURE,
			variables: params,
		});
		const { '__typename': responseTypeName, ...uploadProfilPictureData } = data.data.updatePicture;

		switch (responseTypeName) {
			case ARTIST_PROFILE_UPLOAD_PROFILE_PICTURE_RESPONSE.artist:
				commit('SET_USER', uploadProfilPictureData);
				break;

			case ARTIST_PROFILE_UPLOAD_PROFILE_PICTURE_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
		commit('TOGGLE_IS_LOADING_PROFILE_PICTURE', false);
	}
};

const UPLOAD_BANNER_PICTURE = async ({ commit }, params) => {
	try {
		commit('TOGGLE_IS_LOADING_BANNER_PICTURE', true);

		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_PROFILE_UPLOAD_BANNER_PICTURE,
			variables: params,
		});
		const { '__typename': responseTypeName, ...uploadProfilBannerData } = data.data.updatePicture;

		switch (responseTypeName) {
			case ARTIST_PROFILE_UPLOAD_BANNER_PICTURE_RESPONSE.artist:
				commit('SET_USER', uploadProfilBannerData);
				break;

			case ARTIST_PROFILE_UPLOAD_BANNER_PICTURE_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
		commit('TOGGLE_IS_LOADING_BANNER_PICTURE', false);
	}
};

const UPLOAD_MEDIA = async ({ commit }, params) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_UPLOAD_MEDIA,
			variables: params,
		});
		const { '__typename': responseTypeName, ...uploadMediaResult } = data.data.updateMedia;

		switch (responseTypeName) {
			case ARTIST_UPLOAD_MEDIA_RESPONSE.artist:
				commit('SET_USER', uploadMediaResult);
				break;

			case ARTIST_UPLOAD_MEDIA_RESPONSE.uploadError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('artist.editMedias.errors.uploadError') }], { root: true });
				break;

			case ARTIST_UPLOAD_MEDIA_RESPONSE.unknownError:
			case ARTIST_UPLOAD_MEDIA_RESPONSE.unauthorized:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t(`common.errors.${responseTypeName}`) }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const DELETE_MEDIA = async ({ commit }, params) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_DELETE_MEDIA,
			variables: params,
		});
		const { '__typename': responseTypeName, ...deleteMediaResult } = data.data.deleteMedia;

		switch (responseTypeName) {
			case ARTIST_DELETE_MEDIA_RESPONSE.medias:
				commit('SET_USER', deleteMediaResult);
				break;

			case ARTIST_UPLOAD_MEDIA_RESPONSE.unknownError:
			case ARTIST_DELETE_MEDIA_RESPONSE.unauthorized:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t(`common.errors.${responseTypeName}`) }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const UPDATE_MEDIA_RANKING = async ({ commit }, ranking) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_UPDATE_MEDIA_RANKING_QUERY,
			variables: { input: ranking },
		});
		const { '__typename': responseTypeName, ...updateMediaRankData } = data.data.updateMediaRank;

		switch (responseTypeName) {
			case ARTIST_UPDATE_MEDIA_RANKING_RESPONSE.artist:
				commit('SET_USER', updateMediaRankData);
				break;

			case ARTIST_UPDATE_MEDIA_RANKING_RESPONSE.tokenError:
			case ARTIST_UPDATE_MEDIA_RANKING_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;

			default: break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const UPLOAD_PRIVATE_DOCUMENT = async ({ commit }, input) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_UPLOAD_PRIVATE_DOCUMENT,
			variables: { input }
		});
		const { '__typename': responseTypeName, ...uploadPrivateDocumentData } = data.data.uploadPrivateDocument;

		switch (responseTypeName) {
			case ARTIST_UPLOAD_PRIVATE_DOCUMENT_RESPONSE.artist:
				commit('SET_USER', uploadPrivateDocumentData);
				break;

			case ARTIST_UPLOAD_PRIVATE_DOCUMENT_RESPONSE.uploadError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('artist.editMedias.errors.uploadError') }], { root: true });
				break;

			case ARTIST_UPLOAD_PRIVATE_DOCUMENT_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const EDIT_FAQ = async ({ commit }, params) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_EDIT_FAQ,
			variables: params
		});
		const { '__typename': responseTypeName, ...editFAQData } = data.data.editFAQ;

		switch (responseTypeName) {
			case ARTIST_EDIT_FAQ_RESPONSE.faq:
				commit('SET_USER', { FAQ: editFAQData.QandA });
				break;

			case ARTIST_EDIT_FAQ_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;

			default: break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const SUBSCRIBE_TO_VIDEO_UPLOAD_NOTIFICATION = ({ state, commit }) => {
	// Reconnection with Token
	wsLink.subscriptionClient.close();
	wsLink.subscriptionClient.connect();

	const subscription = apolloProvider.defaultClient.subscribe({
		query: CLOUDINARY_VIDEO_UPLOAD_NOTIFICATION,
	});

	subscription.subscribe({
		next({ data = {} }) {
			if (data?.videoReady) {
				const updatedUserMedias = [
					...state.user.Medias.filter(({ ID }) => ID !== data.videoReady.ID),
					{
						...state.user.Medias.find(({ ID }) => ID === data.videoReady.ID),
						URL: data.videoReady.URL,
					},
				];

				commit('SET_USER', { Medias: updatedUserMedias });
			}
		},
		error() {
			commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
		},
	});
};

const SUBSCRIBE_TO_EMAIL_VALIDATION = ({ getters, commit }, ID) => {
	const subscription = apolloProvider.defaultClient.subscribe({
		query: EMAIL_VALIDATE_SUBSCRIPTION,
		variables: { ID },
	});

	subscription.subscribe({
		next({ data = {} }) {
			if (data?.emailValidate?.message) {
				const userWithToken = { Token: data.emailValidate.message };

				commit('SET_USER', userWithToken);
				commit('AUTHENTICATE', userWithToken);
				router.push({
					name: getters.IS_ARTIST_USER ? 'ArtistMailValidated' : 'ClientEmboardingIdentity',
					...(getters.IS_ARTIST_USER ? { params: { token: false } } : {}),
				});
			}
		},
		error() {
			commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
		},
	});
};

const VERIFY_PHONE_NUMBER_CODE = async ({ rootState, commit }, params) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_VERIFY_PHONE_NUMBER_CODE,
			variables: params,
		});
		const { __typename: responseTypeName, ...verifyPhonenumberCodeData } = data.data.verifyPhonenumberCode;

		switch (responseTypeName) {
			case ARTIST_VERIFY_PHONE_NUMBER_CODE_RESPONSE.artist:
				commit('SET_USER', verifyPhonenumberCodeData);

				return true;

			case ARTIST_VERIFY_PHONE_NUMBER_CODE_RESPONSE.client:
				commit('SET_USER', verifyPhonenumberCodeData);
				router.push(rootState.Routing.pendingRedirect || { name: 'ClientEmboardingWelcome' });
				commit('Routing/SET_PENDING_REDIRECT', null, { root: true });
				break;

			case ARTIST_VERIFY_PHONE_NUMBER_CODE_RESPONSE.noSMSCodeFound:
			case ARTIST_VERIFY_PHONE_NUMBER_CODE_RESPONSE.wrongSMSCode:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t(`artist.phoneValidation.errors.${responseTypeName}`) }], { root: true });

				return false;

			case ARTIST_VERIFY_PHONE_NUMBER_CODE_RESPONSE.unknownError:
			default:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const RECOVER_PASSWORD = async ({ commit }, params) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: PASSWORD_RECOVERY,
			variables: params,
		});
		const { __typename: responseTypeName } = data.data.forgotPassword;

		switch (responseTypeName) {
			case PASSWORD_RECOVERY_RESPONSE.operationInformation:
				return true;

			case PASSWORD_RECOVERY_RESPONSE.userNotFound:
			case PASSWORD_RECOVERY_RESPONSE.wrongInput:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t(`artist.passwordRecovery.errors.${responseTypeName}`) }], { root: true });
				return false;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const RESET_PASSWORD = async ({ commit }, password) => {
	try {
		const input = { password };
		const data = await apolloProvider.defaultClient.mutate({
			mutation: PASSWORD_RESET,
			variables: input
		});
		const { __typename: responseTypeName } = data.data.resetPassword;

		switch (responseTypeName) {
			case PASSWORD_RESET_RESPONSE.artist:
				router.push({ name: 'ArtistDashboard' });
				break;

			case PASSWORD_RESET_RESPONSE.client:
				router.push({ name: 'ClientDashboard' });
				break;

			case PASSWORD_RESET_RESPONSE.wrongInput:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t(`artist.passwordReset.errors.${responseTypeName}`) }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const TOGGLE_PUBLISH_ARTIST = async ({ state, getters, commit, dispatch }) => {
	try {
		const isArtistCurrentlyPublished = state.user.IsActive;
		const shouldSetPricing = !isArtistCurrentlyPublished && !getters['HAS_SETUP_PRICINGS'];

		if (shouldSetPricing) {
			const params = {
				input: {
					PriceNormal: state.user.DefaultPrices.Normal,
					PriceException: state.user.DefaultPrices.Exception,
				},
			};

			await dispatch('EDIT_IDENTITY', params);
		}

		const data = await apolloProvider.defaultClient.mutate({
			mutation: isArtistCurrentlyPublished
				? UNPUBLISH_ARTIST_PROFILE
				: PUBLISH_ARTIST_PROFILE,
		});
		const { '__typename': responseTypeName, ...publishArtistData } = isArtistCurrentlyPublished
			? data.data.unpublishProfil
			: data.data.publishProfil;

		switch (responseTypeName) {
			case PUBLISH_ARTIST_PROFILE_RESPONSE.artist:
			case UNPUBLISH_ARTIST_PROFILE_RESPONSE.artist:
				commit('SET_USER', publishArtistData);
				break;

			// TODO: Custom error message
			case PUBLISH_ARTIST_PROFILE_RESPONSE.pricesShouldBeSet:
			case UNPUBLISH_ARTIST_PROFILE_RESPONSE.pricesShouldBeSet:
			case PUBLISH_ARTIST_PROFILE_RESPONSE.stripeIsNotSetup:
			case UNPUBLISH_ARTIST_PROFILE_RESPONSE.stripeIsNotSetup:
			case PUBLISH_ARTIST_PROFILE_RESPONSE.algoliaError:
			case UNPUBLISH_ARTIST_PROFILE_RESPONSE.algoliaError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;

			case UNPUBLISH_ARTIST_PROFILE_RESPONSE.unknownError:
			case PUBLISH_ARTIST_PROFILE_RESPONSE.unknownError:
			case UNPUBLISH_ARTIST_PROFILE_RESPONSE.tokenError:
			case PUBLISH_ARTIST_PROFILE_RESPONSE.tokenError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;

			default: break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};


const EDIT_STATUS_INFORMATIONS = async ({ commit }, input) => {
	try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: EDIT_STATUS_INFORMATIONS_QUERY,
			variables: input,
		});
		const { __typename: responseTypeName, ...updateStatusInformationsData } = data.data.updateStatusInformations;

		switch (responseTypeName) {
			case EDIT_STATUS_INFORMATIONS_RESPONSE.artist:
				commit('SET_USER', updateStatusInformationsData);
				break;

			case EDIT_STATUS_INFORMATIONS_RESPONSE.tokenError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};


export default {
	FETCH_ME,
	FETCH_ACTIVITY,
	EDIT_ACTIVITY,
	EDIT_IDENTITY,
	UPLOAD_PROFILE_PICTURE,
	UPLOAD_BANNER_PICTURE,
	UPLOAD_MEDIA,
	DELETE_MEDIA,
	UPDATE_MEDIA_RANKING,
	UPLOAD_PRIVATE_DOCUMENT,
	EDIT_FAQ,
	SUBSCRIBE_TO_VIDEO_UPLOAD_NOTIFICATION,
	TOGGLE_PUBLISH_ARTIST,
	RESET_PASSWORD,
	EDIT_STATUS_INFORMATIONS,
	SUBSCRIBE_TO_EMAIL_VALIDATION,
	VERIFY_PHONE_NUMBER_CODE,
	RECOVER_PASSWORD,
	GET_BOOKINGS,
};
