import { COMMON_API_RESPONSES }     from "../../../../constants/common-api-responses";


export const ARTIST_EDIT_FAQ_RESPONSE = {
  // Success
  faq: 'FAQ',
  faqOwnership: 'FAQOwnerShip',
  // Errors
  ...COMMON_API_RESPONSES,
};
