import gql      from 'graphql-tag';


export const ARTIST_PROFILE_UPLOAD_BANNER_PICTURE = gql`
  mutation updatePicture($input: UpdatePictureInput!) {
    updatePicture(input: $input) {
      __typename
      ... on Artist {
        URLBannerPictures
        BannerPicturesCropsOptions
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
