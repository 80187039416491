import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses.js';


export const ARTIST_VERIFY_PHONE_NUMBER_CODE_RESPONSE = {
  // Success
  artist: 'Artist',
  client: 'Client',
  // Managed Failures
  noSMSCodeFound: 'NoSMSCodeFound',
  wrongSMSCode: 'WrongSMSCode',
  // Errors
  ...COMMON_API_RESPONSES,
};
