import { COMMON_API_RESPONSES }			from "../../../constants/common-api-responses.js";


export const USER_ME_RESPONSE = {
	// Success
	artist: 'Artist',
	client: 'Client',

	// Common
	...COMMON_API_RESPONSES
};
