const isNewAppVersionAvailable = () => {
  const isProductionOrBeta = process.env.NODE_ENV === 'production';
  const currentAppVuexStore = localStorage.getItem(process.env.VUE_APP_VERSION);
  const hasNewAppVersionAvailableOrShouldInitVersioning = isProductionOrBeta && !currentAppVuexStore;

  return hasNewAppVersionAvailableOrShouldInitVersioning;
};

const clearVuexLocalStorage = () => {
  window.localStorage.clear();
};


export {
  isNewAppVersionAvailable,
  clearVuexLocalStorage,
};
