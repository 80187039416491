import gql      from 'graphql-tag';


export const BOOK_ARTIST = gql`
  mutation bookArtist($input: BookArtistInput!) {
    bookArtist(input: $input) {
      ... on PendingBooking {
        ID
        Secret
        Amount
      }

      ... on ArtistNotAvailable {
        message
        status
      }

      ... on AvailabilityNotFound {
        message
        status
      }

      ... on ArtistNotFound {
        message
        status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
