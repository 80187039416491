import gql from 'graphql-tag';


export const GET_BOOKINGS = gql`
 query me {
  me {
    __typename
    ... on Artist {
      Bookings {
        ID
        EventType
        EventDate
        PriceForClient
        PriceForArtist
        CanceledBeforeDueDate
        Line1
        Line2
        PostalCode
        City
        Country
        StartTime
        EndTime
        Status
        IsMaterialNeeded
        IsCanceledByClient
        NumberOfPeople
        Client {
          Email
          Firstname
          Lastname
          PhoneNumber
        }
        Artist {
          ID
          Email
          Firstname
          Lastname
          ArtisteNicknameGenerated
          PhoneNumber
          URLProfilePictures
          ProfilePicturesCropsOptions
        }
      }
    }

    ... on Client {
      Bookings {
        ID
        EventType
        EventDate
        PriceForClient
        PriceForArtist
        CanceledBeforeDueDate
        Line1
        Line2
        PostalCode
        City
        Country
        StartTime
        EndTime
        Status
        IsMaterialNeeded
        IsCanceledByClient
        NumberOfPeople
        ClientEventType
        Client {
          Email
          Firstname
          Lastname
          PhoneNumber
        }
        Artist {
          ID
          Email
          Firstname
          Lastname
          ArtisteNicknameGenerated
          PhoneNumber
          URLProfilePictures
          ProfilePicturesCropsOptions
        }
      }
    }

    ... on TokenError {
      status
      message
      requestID
    }
  }
}

`;
