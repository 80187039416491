import algoliasearch													from 'algoliasearch';

import FILTER_OPTIONS													from '../../features/ClientDashboard/constants/c-filter-options.js';
import {
	getDateAsDDMMYYYY,
}																							from '../../utils/dateUtils.js';
import {
	getCroppedPictureURL,
	getTrimmedVideoURL
}																							from '../../utils/URLUtils.js';


const ALGOLIA_CLIENT = () => algoliasearch(
	process.env.VUE_APP_ALGOLIA_APP_ID,
	process.env.VUE_APP_ALGOLIA_API_KEY,
);

const ALGOLIA_INDEX = (state) => state.sortPricingBy || process.env.VUE_APP_ALGOLIA_INDEX;

const ACTIVE_FILTERS = (state) => {
	const activeFilters = Object.entries(state.filters).reduce((acc, [ filterName, filterValue ]) => {
		const isActive = Array.isArray(filterValue)
			? filterValue.length > 0
			: filterValue && typeof filterValue === 'object'
				? Object.keys(filterValue).length > 0
				: !!filterValue;

		return isActive
			? { ...acc, [filterName]: filterValue }
			: acc;
	}, {});

	return activeFilters;
};

const ACTIVE_FILTERS_COUNTER = (state, getters) => Object.values(getters['ACTIVE_FILTERS'])?.length ?? 0;

const ALGOLIA_FILTERS = (state, getters) => {
	if (!getters['ACTIVE_FILTERS_COUNTER']) return {};

	const filters = Object
		.entries(getters['ACTIVE_FILTERS'])
		.reduce((acc, [ filterName, filterValue ]) => {
			if (['localisation', 'tags'].includes(filterName)) return acc;

			const {
				algoliaFieldName = '',
				algoliaJoinOperator = '',
				algoliaPrefixOperator = '',
				algoliaOperator = ':',
				algoliaCombineOperator = ' AND ',
			} = FILTER_OPTIONS.find(({ value }) => value === filterName);
			const filterQuery = Array.isArray(filterValue)
				? filterValue.map(value => algoliaPrefixOperator
						? `${algoliaPrefixOperator} ${algoliaFieldName}${algoliaOperator}${value}`
						: `${algoliaFieldName}${algoliaOperator}${value}`
					).join(`${algoliaJoinOperator}`)
				: algoliaPrefixOperator
					? `${algoliaPrefixOperator} ${algoliaFieldName}${algoliaOperator}${filterValue}`
					: `${algoliaFieldName}${algoliaOperator}${filterValue}`;

			return acc
				? `${acc}${algoliaCombineOperator}(${filterQuery})`
				: `(${filterQuery})`;
	}, '');

	return {
		filters,
		...(state.filterQuery ? { query: state.filterQuery } : {}),
		...getters['GEOLOCALISATION_FILTER'],
	};
};

const GEOLOCALISATION_FILTER = (state, getters) => {
	const { geometry } = getters['ACTIVE_FILTERS']?.localisation ?? {};

	return geometry
		? {
				getRankingInfo: true,
				aroundRadius: 'all',
				aroundLatLng: `${geometry.location.lat}, ${geometry.location.lng}`,
			}
		: {};
};

const IS_CUSTOM_QUOTE_NEEDED = (state, getters) => state.isCustomQuote && getters['GEOLOCALISATION_FILTER'].getRankingInfo;

const CALENDAR_AVAILABILITIES_FILTER = (state) => {
	if (!state?.selectedAvailability?.timestamp) return [];

	const calendarAvailabilities = [state.selectedAvailability].map(({ timestamp }) => ({
		key: `client-selected-${timestamp}`,
		dates: [timestamp],
	}));

	return calendarAvailabilities;
};

const CALENDAR_AVAILABILITIES = (state) => {
	const bookings = state.currentArtist.Bookings ?? [];
	const availabilities = state.currentArtist?.Availabilities ?? [];

	const calendarAvailabilities = availabilities.map(availability => {
		const isBookedAvailability = bookings.some(({ EventDate }) => EventDate === availability.EventDate);
		const clientSelectedAvailability = state.selectedAvailability.EventDate === availability.EventDate;

		return {
			key: isBookedAvailability
				? `${availability.EventType.toLowerCase()}-booked`
				: clientSelectedAvailability
					? `client-selected-${state.selectedAvailability.timestamp}`
					: availability.EventType.toLowerCase(),
			dates: [availability.EventDate * 1000],
			customData: availability
		}
	});

	return calendarAvailabilities;
};

const CURRENT_ARTIST_SELECTED_AVAILABILITY = (state) => state.currentArtist?.Availabilities?.find(
	({ EventDate }) => EventDate === state.selectedAvailability.EventDate
) ?? null;

const BOOKING_DATE = (state) => state.selectedAvailability.timestamp
	? getDateAsDDMMYYYY(new Date(state.selectedAvailability.timestamp))
	: '';

const PUBLIC_MEDIAS_IMAGES = (state) => {
	return state.currentArtist?.Medias
		?.filter(media => media.Type === 'IMAGE')
		?.map(({ Options, URL, ...image }) => ({
				...image,
				originalURL: URL,
				URL: getCroppedPictureURL(URL, Options),
				...(Object.keys(Options).length ? { options: Options } : {}),
			}))
		?? [];
};

const PUBLIC_MEDIAS_VIDEOS = (state) => {
	return state.currentArtist?.Medias
		?.filter(media => media.Type === 'VIDEO')
		?.map(({ Options, URL, ...video }) => ({
				...video,
				originalURL: URL,
				URL: getTrimmedVideoURL(URL, Options),
				...(Object.keys(Options).length ? { options: Options } : {}),
			}))
		?? [];
};

const HAS_ANSWERED_QUESTIONS = (state) => state.currentArtist?.FAQ?.some(question => question.Answer !== '') ?? false;

const HAS_PENDING_BOOKING = (state, getters, rootState, rootGetters) => {
	return !rootGetters['User/IS_ARTIST_USER'] &&
		rootState.SearchArtist.currentArtist?.ID &&
		rootState.Routing?.pendingRedirect;
};


export default {
	ACTIVE_FILTERS,
	ACTIVE_FILTERS_COUNTER,
	GEOLOCALISATION_FILTER,
	ALGOLIA_CLIENT,
	ALGOLIA_INDEX,
	ALGOLIA_FILTERS,
	CALENDAR_AVAILABILITIES,
	CALENDAR_AVAILABILITIES_FILTER,
	PUBLIC_MEDIAS_IMAGES,
	PUBLIC_MEDIAS_VIDEOS,
	BOOKING_DATE,
	CURRENT_ARTIST_SELECTED_AVAILABILITY,
	HAS_ANSWERED_QUESTIONS,
	IS_CUSTOM_QUOTE_NEEDED,
	HAS_PENDING_BOOKING,
};
