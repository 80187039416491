import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses.js';


export const CANCEL_CLIENT_BOOKING_RESPONSE = {
  // Success
  client: 'Client',
  // Managed Failures
  bookingNotFound: 'BookingNotFound',
  stripeError: 'StripeError',
  // Errors
  ...COMMON_API_RESPONSES,
};
