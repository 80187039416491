import gql      from 'graphql-tag';


export const CLOUDINARY_VIDEO_UPLOAD_NOTIFICATION = gql`
  subscription videoReady {
    videoReady {
      __typename

      ... on VideoReady {
          ID
          URL
          Message
        }
    }
  }
`;
