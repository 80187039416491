import { getInitialState }      from './index.js';


const SET_STRIPE_URL = (state, url) => {
  state.stripeUrl = url;
};

const SET_STRIPE_ERROR = (state, hasError) => {
	state.hasError = hasError;
};

const CLEAR_STATE = (state) => {
  Object.assign(state, getInitialState());
};


export default {
  SET_STRIPE_URL,
	SET_STRIPE_ERROR,
  CLEAR_STATE,
};