import { COMMON_API_RESPONSES }     from "../../../../constants/common-api-responses";


export const ARTIST_UPLOAD_PRIVATE_DOCUMENT_RESPONSE = {
    // Success
    artist: 'Artist',
    // Managed Failures
    uploadError: 'uploadError',
    ...COMMON_API_RESPONSES,
};
