import gql      from 'graphql-tag';


export const PASSWORD_RECOVERY = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      __typename

      ... on OperationInformation {
        message
        status
      }

      ... on UserNotFound {
        message
        status
      }

      ... on WrongInput {
        errors {
          message
          status
        }
      }
    }
  }
`;
