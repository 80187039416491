import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';


const getInitialState = () => ({
  user: {
    ActivityInformations: {
      __typename: 'NotFetched'
    }
  },
  isUserLoading: false,
  isUserAuthenticated: false,
  isLoadingProfilePicture: false,
  isLoadingBannerPicture: false,
  isRGPDAccepted: false,
  locale: {
    locale: 'fr',
    label: 'Français'
  },
});


export { getInitialState };
export default {
  namespaced: true,
  state: getInitialState(),
  actions,
  getters,
  mutations,
};
