import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses.js';


export const EDIT_CLIENT_IDENTITY_RESPONSE = {
  // Success
  client: 'Client',
  // Managed Failures
  emailNotValidate: 'EmailNotValidate',
  // Errors
  ...COMMON_API_RESPONSES,
};
