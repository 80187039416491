import gql      from 'graphql-tag';


export default gql`
  query getLanding {
    getLanding {
      __typename

      ... on Landing {
        VideoURL
        FeaturedArtists {
          ID
          Firstname
          ArtisteNickname
          Activity
          City
          State
          Picture
          PictureCropOption
          Rank
        }
        BannerArtists {
          Rank
          MediaURL
          MediaURLCropOption
          ArtisteNickname
        }
        Comments
      }
    }
  }
`;
