import gql from 'graphql-tag';


export const ARTIST_AVAILABILITIES = gql`
query availabilities($date: Int!) {
  availabilities(date: $date) {
    ... on Availabilities {
      availabilities {
        Id
        EventType
        EventDate
			}
    }

    ... on AvailabilityNotFound {
      message
      status
    }

    ... on NoTokenFound {
      message
      status
    }

    ... on ExpiredToken {
      message
      status
    }

    ... on WrongToken {
      message
      status
    }
  }
}
`;
