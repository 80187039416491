<template>
  <div
    id="app"
    ref="app">
    <router-view name="header"></router-view>
    <router-view name="default"></router-view>
    <router-view name="footer"></router-view>

    <Toaster></Toaster>
    <RGPDBox v-if="!isRGPDAccepted"></RGPDBox>
  </div>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapActions,
}                   from 'vuex';

import Toaster      from '../src/components/molecules/Toaster/m-Toaster.vue';
import RGPDBox      from '../src/components/molecules/RGPDBox/m-RGPDBox.vue';


export default {
  name: 'App',
  components: {
    Toaster,
    RGPDBox,
  },
  computed: {
    ...mapState('User', ['isRGPDAccepted']),
    ...mapGetters({
      isArtistUser: 'User/IS_ARTIST_USER',
    }),
  },
  watch: {
    '$route'() {
      this.handleBackgroundColorSetting();
    },
    isArtistUser: {
      immediate: true,
      handler(isArtistUser) {
        if (isArtistUser) this.subscribeToVideoUploadNotifications();
      },
    },
  },
  methods: {
    ...mapActions({
      subscribeToVideoUploadNotifications: 'User/SUBSCRIBE_TO_VIDEO_UPLOAD_NOTIFICATION',
    }),
    handleBackgroundColorSetting() {
      const color = this.$route.meta.isGreyBackground
        ? 'var(--color-grey-background)'
        : 'var(--color-white)';

      this.$refs.app.style.backgroundColor = color;
      document.body.backgroundColor = color;
    },
  },
}

</script>
