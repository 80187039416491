import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses.js';


export const BOOK_ARTIST_RESPONSE = {
  // Success
  pendingBooking: 'PendingBooking',
  // Managed Failures
  artistNotAvailable: 'ArtistNotAvailable',
  availabilityNotFound: 'AvailabilityNotFound',
  artistNotFound: 'ArtistNotFound',
  unauthorized: 'Unauthorized',
  // Errors
  ...COMMON_API_RESPONSES,
};
