import gql from 'graphql-tag';


export const GET_STRIPE_URL = gql`
  query getStripeURL {
    getStripeURL {
      __typename

      ... on SetupPaymentURL {
        URL
        Created
        ExpiresAt
      }

      ... on DashboardURL {
        URL
        Created
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
