import gql      from 'graphql-tag';


export const ARTIST_PUBLIC_AVAILABILITIES = gql`
  query publicAvailabilities($input: PublicAvailitibliesInput!) {
    publicAvailabilities(input: $input) {
      __typename

      ... on Availability {
        Id
        EventType
        EventDate
      }
    }
  }
`;
