import gql      from 'graphql-tag';


export const EMAIL_VALIDATE_SUBSCRIPTION = gql`
  subscription emailValidate ($ID: String!) {
    emailValidate(ID: $ID) {
      ... on OperationInformation {
        message
        status
      }
    }
  }`
