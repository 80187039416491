import Vue                    from 'vue';
import VueRouter              from 'vue-router';
import VCalendar              from 'v-calendar/lib/components/calendar.umd';
import VueApollo              from 'vue-apollo';
import VueI18n                from 'vue-i18n';
import InstantSearch 					from 'vue-instantsearch';
import VueGtm 								from 'vue-gtm';


import App 										from './App.vue';
import router 								from './router/router.js';
import store 									from './store/index.js';
import { apolloProvider }			from './graphql/config.js';
import messages 							from './translations/index.js';


const mediaWatcher = window.matchMedia('(min-width: 1000px)');

Vue.use(VueApollo);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(InstantSearch);
Vue.use(VueGtm, {
	id: 'GTM-NJWVRDQ',
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: false, // Whether or not display console logs debugs (optional)
	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
});
Vue.component('v-calendar', VCalendar);
Vue.config.productionTip = false;
Vue.prototype.$mediaQueries = Vue.observable({ isDesktop: mediaWatcher.matches });
mediaWatcher.addListener(({ matches }) => Vue.prototype.$mediaQueries.isDesktop = matches);


const i18n = new VueI18n({
	locale: store.state.User.locale.locale,
	messages,
	silentTranslationWarn: true,
	silentFallbackWarn: true,
});

new Vue({
	render: h => h(App),
	router,
	store,
	apolloProvider,
	i18n,
}).$mount('#app');


export {
	i18n
};
