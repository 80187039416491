import { getInitialState }      from './index.js';


const SET_LANDING_DATA = (state, landingData) => {
  state.landingData = landingData;
};

const CLEAR_STATE = (state) => {
  Object.assign(state, getInitialState());
};


export default {
  SET_LANDING_DATA,
  CLEAR_STATE,
};
