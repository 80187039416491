import gql      from 'graphql-tag';


export default gql`
  mutation cancelClientBooking($bookingID: String!) {
    cancelClientBooking(bookingID: $bookingID) {
      ... on Client {
        Bookings {
          ID
          EventType
          EventDate
          PriceForClient
          PriceForArtist
          CanceledBeforeDueDate
          Line1
          Line2
          PostalCode
          City
          Country
          StartTime
          EndTime
          Status
          IsMaterialNeeded
          IsCanceledByClient
          NumberOfPeople
          ClientEventType
          Client {
            Email
            Firstname
            Lastname
            PhoneNumber
          }
          Artist {
            Email
            Firstname
            Lastname
            PhoneNumber
          }
        }
      }

      ... on BookingNotFound {
        message
        status
      }

      ... on StripeError {
        message
        Status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
