import gql      from 'graphql-tag';


export const ARTIST_DELETE_MEDIA = gql`
  mutation deleteMedia($id: String!) {
    deleteMedia(id: $id) {
      __typename

      ... on Medias {
        Medias {
          ID
          URL
          Type
          Options
          Rank
        }
      }

      ... on MediaOwnerShip {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
