import gql      from 'graphql-tag';


export const EDIT_ARTIST = gql`
mutation ($input: UpdateArtistInput!) {
  updateArtist(input: $input) {
    __typename
    ... on Artist {
      ArtisteNickname
      ArtisteNicknameGenerated
      Birthday
      BirthdayLocation
      ChildrenPermission
      Description
      Firstname
      Gender
      PhoneNumber
      Line1
      Line2
      PostalCode
      State
      City
      Country
      Lastname
      LocationRange
      PerformanceDuration
      IsAdditionalHourOk
      Tags
      Languages
      FAQ {
        ID
        QuestionTag
        Answer
      }
      ClientPrices {
        Normal
        Exception
      }
      DefaultPrices {
        __typename
        Normal
        Exception
      }
      Price {
        __typename
        Normal
        Exception
      }
      Token
      URLProfilePictures
      URLBannerPictures
    }

    ... on WrongInput {
      __typename
      errors {
        message
        status
      }
    }

    ... on TokenError {
      message
      status
      requestID
    }
  }
}
`;
