import ACTIVITY_OPTIONS     from '../../../constants/activity-options.js';


export default [
  {
    label: 'client.dashboard.filter.activities',
    value: 'activity',
    link: 'ClientDashboardActivitySorting',
    algoliaJoinOperator: ' OR ',
    algoliaFieldName: 'activity',
    options: ACTIVITY_OPTIONS,
  },
  {
    value: 'tags',
    algoliaJoinOperator: ' OR ',
    algoliaFieldName: 'tags',
    algoliaCombineOperator: ' OR ',
  },
  {
    value: 'language',
    algoliaJoinOperator: ' OR ',
    algoliaFieldName: 'languages',
    algoliaCombineOperator: ' OR ',
  },
  {
    label: 'client.dashboard.filter.allowChildren',
    value: 'childrenPermission',
    link: 'ClientSearchArtistChildrenFilter',
    algoliaJoinOperator: ' OR ',
    algoliaFieldName: 'childrenPermission',
  },
  {
    label: 'client.dashboard.filter.availabilities',
    value: 'availability',
    link: 'ClientSearchArtistAvailabilityFilter',
    algoliaJoinOperator: ' OR ',
    algoliaPrefixOperator: 'NOT',
    algoliaFieldName: 'unavailabilities',
  },
  {
    label: 'client.dashboard.filter.location',
    value: 'localisation',
    link: 'ClientSearchArtistLocationFilter',
  },
  {
    label: 'client.dashboard.filter.pricing',
    value: 'pricing',
    link: 'ClientSearchArtistPriceFilter',
    algoliaOperator: '<=',
    algoliaFieldName: 'priceNormal',
  },
];
