import gql      from 'graphql-tag';


export const GET_ACTIVITY = gql`
  query activity {
    ... on ArtistActivity {
    Activity {
      __typename

      ... on Animator {
        NumberOfClientSupported
      }

      ... on Band {
        NumberOfMembers
      }

      ... on CircusArtist {
        NumberOfTricks
      }

      ... on Dancer {
        NumberOfMembers
      }

      ... on Doppleganger {
        NumberOfCostumes
      }

      ... on Hypnotiser {
        NumberOfTricks
      }

      ... on Magician {
        NumberOfTricks
      }

      ... on MakeupArtist {
        NumberOfClientSupported
      }

      ... on Mentalist {
        NumberOfTricks
      }

      ... on Puppeteer {
        NumberOfTricks
      }
    }

      ... on TokenError {
        status
        message
      }
    }
  }
`;
