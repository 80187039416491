<template>
  <article class="toaster__wrapper">
    <ul class="toaster__toast-list">
      <transition-group name="vertical-slide-down-up" appear>
        <li
          v-for="(toast, index) in toasts"
          :key="`toaster-toast-item-${index}`"
          class="toaster__toast-item">
          <Toast
            v-bind="toast"
            :duration="toast.duration || defaultToastDuration"
            :key="`toaster-toast-${toast.id}`">
          </Toast>
        </li>
      </transition-group>
    </ul>
  </article>
</template>

<script>

import { mapState }     from 'vuex';

import Toast            from '../../../components/atoms/Toast/a-Toast.vue';


export default {
  name: 'm-Toaster',
  components: { Toast },
  data: () => ({
    defaultToastDuration: 5000,
  }),
  computed: {
    ...mapState({ toasts: state => state.errors }),
  }
}

</script>

<style lang="scss">

.toaster {
  &__wrapper {
    z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    bottom: var(--space-base);
    max-width: calc(100% - (var(--space-base) * 2));
    margin: 0 var(--space-base);

    @media screen and ($tablet) {
      margin: 0 auto;
    }

    @media screen and ($desktop) {
      max-width: calc(1000px - (var(--space-base) * 2));
    }
  }

  &__toast-list {
    display: flex;
    flex-direction: column;
  }

  &__toast-item {
    &:not(:last-child) {
      margin-bottom: var(--space-xxs);
    }
  }
}

</style>