import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses.js';


export const CANCEL_ARTIST_BOOKING_RESPONSE = {
  // Success
  artist: 'Artist',
  // Managed Failures
  bookingNotFound: 'BookingNotFound',
  stripeError: 'StripeError',
  // Errors
  ...COMMON_API_RESPONSES,
};
