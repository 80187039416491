import actions 				from './actions.js';
import getters 				from './getters.js';
import mutations			from './mutations.js';


const getInitialState = () => ({
	isLoading: false,
	isCustomQuote: false,
	currentArtistLocation: {
		lat: '',
		lng: '',
	},
	currentArtist: {},
	bookingDetails: {
		availabilityID: '',
		artistID: '',
		Line1: '',
		Line2: '',
		PostalCode: '',
		City: '',
		Country: '',
		StartTime: '',
		IsMaterialNeeded: false,
		NumberOfPeople: '',
		EventType: '',
	},
	selectedAvailability: {},
	filterQuery: '',
	filters: {
		activity: [],
		tags: [],
		language: [],
		childrenPermission: [],
		availability: [],
		localisation: {},
		pricing: 5000,
	},
	sortPricingBy: '',
	stripeIntent: {
		Amount: 0,
		Secret: '',
		ID: ''
	},
});


export { getInitialState };
export default {
	namespaced: true,
	state: getInitialState(),
	actions,
	getters,
	mutations,
};
