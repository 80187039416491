<template>
  <component
    v-on="{
      ...$listeners,
      mousedown: $event => $event.preventDefault()
    }"
    v-bind="$attrs"
    v-html="iconMarkup"
    :is="tag"
    :class="[iconStyle, 'icon__wrapper']">
    <!-- @slot Custom content  -->
    <slot></slot>
  </component>
</template>

<script>

import iconsMap     from './iconsMap.js';


export default {
  name: 'a-Icon',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    variant: {
      type: String,
      required: true,
      validator: variant => Object.keys(iconsMap).includes(variant)
    },
    size: {
      type: String,
      default: 'md',
      validator: size => ['xxs','xs', 'sm', 'md', 'lg', 'xl', 'main'].includes(size),
    },
    color: {
      type: String,
      default: 'black',
      validator: color => ['gradient', 'primary', 'white', 'black', 'light', 'neutral', 'dark', 'valid', 'warning', 'alert'].includes(color),
    },
    backdropColor: {
      type: String,
      default: '',
      validator: color => ['', 'light', 'gradient', 'white', 'black', 'dark'].includes(color),
    },
    isCircled: {
      type: Boolean,
      default: false,
    },
    backdropSize: {
      type: String,
      default: 'base',
      validator: size => ['sm', 'base'].includes(size),
    },
  },
  computed: {
    iconMarkup() {
      const gradientDefinition = this.color === 'gradient' ? iconsMap['gradient-definition'] : '';
      let iconMarkup = iconsMap[this.variant] ?? '';

      if (gradientDefinition) {
        iconMarkup = iconMarkup.replace(
          'xmlns="http://www.w3.org/2000/svg">',
          `xmlns="http://www.w3.org/2000/svg">${gradientDefinition}`
        );
      }

      return iconMarkup;
    },
    iconStyle() {
      return {
        [`icon__wrapper--${this.size}`]: true,
        [`icon__wrapper--${this.color}`]: true,
        'icon__wrapper--circled': this.isCircled,
        'icon--button': ['a', 'button'].includes(this.tag),
        [`icon__wrapper--${this.backdropColor}-backdrop`]: Boolean(this.backdropColor),
        [`icon__wrapper--${this.backdropColor}-backdrop-${this.backdropSize}`]: Boolean(this.backdropColor),
      };
    }
  }
}

</script>

<style lang="scss">

@mixin iconButtonBackdrop($background-color: var(--color-white), $size: 40px) {
  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    height: $size;
    width: $size;
    top: 50%;
    left: 50%;
    background: $background-color;
    border-radius: var(--rounded-circle);
    transform: translate(-50%, -50%);
  }
}

.icon {
  &__wrapper {
    z-index: 1;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    fill: currentColor;

    svg {
      height: 100%;
      width: 100%;
    }

    $sizes: (
      'xxs': var(--icon-xxs),
      'xs': var(--icon-xs),
      'sm': var(--icon-sm),
      'md': var(--icon-md),
      'lg': var(--icon-lg),
      'xl': var(--icon-xl),
      'main': calc(.8 * var(--space-main-height)),
    );

    $colors: (
      'gradient': var(--color-gradient-main),
      'primary': var(--color-primary),
      'white': var(--color-white),
      'black': var(--color-black),
      'light': var(--color-grey-light),
      'neutral': var(--color-grey-neutral),
      'dark': var(--color-grey-dark),
      'valid': var(--color-valid),
      'warning': var(--color-warning),
      'alert': var(--color-alert),
    );

    @each $size-key, $size-value in $sizes {
      &--#{$size-key} {
        height: $size-value;
        width: $size-value;
      }
    }

    @each $color-key, $color-value in $colors {
      &--#{$color-key} {
        @if $color-key == 'gradient' {
          svg, path {
            fill: url(#gradient);
          }
        } @else {
          color: $color-value;
        }
      }
    }

    &--white-backdrop {
      &-base {
        @include iconButtonBackdrop;
      }
      &-sm {
        @include iconButtonBackdrop(var(--color-white), 30px);
      }
    }

    &--dark-backdrop {
      &-base {
        @include iconButtonBackdrop(var(--color-grey-dark));
      }
      &-sm {
        @include iconButtonBackdrop(var(--color-grey-dark), 30px);
      }
    }

    &--gradient-backdrop {
      &-base {
        @include iconButtonBackdrop(var(--color-gradient-main));
      }
      &-sm {
        @include iconButtonBackdrop(var(--color-gradient-main), 30px);
      }
    }

    &--black-backdrop {
      &-base {
        @include iconButtonBackdrop(var(--color-black));
      }
      &-sm {
        @include iconButtonBackdrop(var(--color-black), 30px);
      }
    }

    &--light-backdrop {
      &-base {
        @include iconButtonBackdrop(var(--color-grey-light));
      }
      &-sm {
        @include iconButtonBackdrop(var(--color-grey-light), 30px);
      }
    }

    &--circled {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 1px solid currentColor;
        border-radius: var(--rounded-circle);
        transform: scale(1.5);
      }
    }
  }
}

</style>
