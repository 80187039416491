export default {
	animator: 'Animator',
	musicien: 'Musicien',
	band: 'Band',
	circusArtist: 'CircusArtist',
	dancer: 'Dancer',
	dj: 'Dj',
	doppleganger: 'Doppleganger',
	hypnotiser: 'Hypnotiser',
	magician: 'Magician',
	makeupArtist: 'MakeupArtist',
	mentalist: 'Mentalist',
	puppeteer: 'Puppeteer',
	singer: 'Singer',
	singerMusicien: 'SingerMusicien',
	notSet: 'NotSet'
};