import gql      from 'graphql-tag';


export const EDIT_STATUS_INFORMATIONS = gql`
  mutation updateStatusInformations($input: UpdateStatusInformationsInput!) {
    updateStatusInformations(input: $input) {
      __typename

      ... on Artist {
        URLCardID
        StatusName
        Status {
          __typename
          ... on Company {
            Siret
            VATNumber
            KBISUrl
            VATTaxable
            IsRCPro
            Name
          }
          ... on Intermittent {
            Nationality
            FamilyStatus
            PlaceOfBirth
            StateOfBirth
            CountryOfBirth
            SocialSecurityNumber
            ShowLeaveNumber
            Iban
            Bic
            TaxRate
          }
        }
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
