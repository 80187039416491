import { getInitialState }			from './index.js';


const SET_ACTIVE_FILTER = (state, filter = { name: '', payload: null }) => {
	state.filters = {
		...state.filters,
		[filter.name]: filter.payload
	};
};

const SET_FILTER_QUERY = (state, query) => {
	state.filterQuery = query;
};

const CLEAR_SEARCH = (state) => {
	Object.assign(state, getInitialState());
};

const CLEAR_ACTIVE_FILTERS = (state) => {
	const { filters } = getInitialState();

	Object.assign(state.filters, filters);
};

const CLEAR_ACTIVE_SECONDARY_FILTERS = (state) => {
	const {
		filters: {
			pricing,
			childrenPermission,
			activity,
			language,
		},
	} = getInitialState();
	const { filters: currentFilters } = state;

	state.filters = { ...currentFilters, pricing, childrenPermission, activity, language };
};

const SET_SORTING_MODE = (state, sortPricingBy = 'desc') => {
	state.sortPricingBy = sortPricingBy;
};

const SET_CURRENT_ARTIST = (state, currentArtist = {}) => {
	state.currentArtist = currentArtist;
};

const TOGGLE_LOADING_STATE = (state, isLoading = false) => {
	state.isLoading = isLoading;
};

const SET_CURRENT_AVAILABILITY = (state, selectedAvailability = {}) => {
	state.selectedAvailability = selectedAvailability;
};

const SET_BOOKING_DETAILS = (state, bookingDetails = {}) => {
	state.bookingDetails = {
		...state.bookingDetails,
		...bookingDetails,
	};
};

const SET_CURRENT_ARTIST_LOCATION = (state, location) => {
	state.currentArtistLocation = location;
};

const CLEAR_BOOKING = state => {
	const {
		bookingDetails,
		selectedAvailability,
		filters,
	} = getInitialState();

	state.bookingDetails = bookingDetails;
	state.selectedAvailability = selectedAvailability;
	state.filter = filters;
};

const SET_STRIPE_INTENT = (state, stripeIntent = {}) => {
	state.stripeIntent = stripeIntent;
};

const SET_IS_CUSTOM_QUOTE_NEEDED = (state, isCustomQuote = false) => {
	state.isCustomQuote = isCustomQuote;
};

const CLEAR_STATE = (state) => {
  Object.assign(state, getInitialState());
};


export default {
	SET_ACTIVE_FILTER,
	SET_CURRENT_ARTIST,
	SET_SORTING_MODE,
	TOGGLE_LOADING_STATE,
	SET_CURRENT_AVAILABILITY,
	SET_BOOKING_DETAILS,
	CLEAR_BOOKING,
	CLEAR_SEARCH,
	SET_STRIPE_INTENT,
	CLEAR_ACTIVE_FILTERS,
	CLEAR_ACTIVE_SECONDARY_FILTERS,
	SET_FILTER_QUERY,
	SET_CURRENT_ARTIST_LOCATION,
	SET_IS_CUSTOM_QUOTE_NEEDED,
	CLEAR_STATE,
};
