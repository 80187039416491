import gql      from 'graphql-tag';


export const PUBLISH_ARTIST_PROFILE = gql`
mutation publishProfil {
  publishProfil {
    __typename

    ... on Artist {
      IsActive
    }

    ... on StripeIsNotSetup {
      message
      Status
    }

    ... on PricesShouldBeSet {
      message
      Status
    }

    ... on AlgoliaError {
      message
      Status
    }

    ... on TokenError {
      message
      status
      requestID
    }
  }
}
`;
