import gql      from 'graphql-tag';


export const ARTIST_PROFILE_UPLOAD_PROFILE_PICTURE = gql`
  mutation updatePicture($input: UpdatePictureInput!) {
    updatePicture(input: $input) {
      __typename
      ... on Artist {
        URLProfilePictures
        ProfilePicturesCropsOptions
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
