import gql      from 'graphql-tag';


export const EDIT_ACTIVITY = gql`
  mutation ($input: UpdateActivityInput!) {
    updateActivity(input: $input) {
      __typename

      ...on Artist {
        Tags
        ActivityInformations {
          ... on Animator {
            NumberOfClientSupported
          }

          ... on Band {
            NumberOfMembers
          }

          ... on CircusArtist {
            NumberOfTricks
          }

          ... on Dancer {
            NumberOfMembers
          }

          ... on Doppleganger {
            NumberOfCostumes
          }

          ... on Hypnotiser {
            NumberOfTricks
          }

          ... on Magician {
            NumberOfTricks
          }

          ... on MakeupArtist {
            NumberOfClientSupported
          }

          ... on Mentalist {
            NumberOfTricks
          }

          ... on Puppeteer {
            NumberOfTricks
          }
        }
      }

      ... on OnlyOneActivity {
        status
        message
      }

      ... on TokenError {
        status
        message
      }
    }
  }
`;
