import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses.js';


export const GET_INVOICE_RESPONSE = {
  // Success
  invoiceURL: 'InvoiceURL',
  bookingNotFound: 'BookingNotFound',
  // Managed Failures
  unauthorized: 'Unauthorized',
  // Errors
  ...COMMON_API_RESPONSES,
};
