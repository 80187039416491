import { apolloProvider }                     				from '../../graphql/config.js';
import { i18n } 																			from '../../main.js';
import { ARTIST_PUBLIC_PROFILE }     									from '../../features/ArtistProfile/api/queries/q-ArtistPublicProfile.js';
import { ARTIST_PUBLIC_PROFILE_MINIATURE }     				from '../../features/ArtistProfile/api/queries/q-ArtistPublicProfileMiniature.js';
import { ARTIST_PUBLIC_PROFILE_RESPONSE }							from '../../features/ArtistProfile/api/responses/r-ArtistPublicProfile.js';
import { BOOK_ARTIST as BOOK_ARTIST_QUERY }						from '../../features/ClientBooking/api/queries/q-BookArtist.js';
import { BOOK_ARTIST_RESPONSE }												from '../../features/ClientBooking/api/responses/r-BookArtist.js';
import { ARTIST_PUBLIC_AVAILABILITIES }								from '../../features/ArtistProfile/api/queries/q-ArtistPublicAvailabilities.js';
import CANCEL_CLIENT_BOOKING_QUERY										from '../../features/ClientBooking/api/queries/q-CancelClientBooking.js';
import { CANCEL_CLIENT_BOOKING_RESPONSE }							from '../../features/ClientBooking/api/responses/r-CancelClientBooking.js';
import CANCEL_ARTIST_BOOKING_QUERY										from '../../features/ClientBooking/api/queries/q-CancelArtistBooking.js';
import { CANCEL_ARTIST_BOOKING_RESPONSE }							from '../../features/ClientBooking/api/responses/r-CancelArtistBooking.js';
import { GET_INVOICE_QUERY }													from '../../features/ClientBooking/api/queries/q-GetInvoice.js';
import { GET_INVOICE_RESPONSE }												from '../../features/ClientBooking/api/responses/r-GetInvoice.js';
import router																					from '../../router/router.js';


const FETCH_ARTIST_PUBLIC_PROFILE = async ({ commit }, artistId) => {
  commit('TOGGLE_LOADING_STATE', true);

  try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: ARTIST_PUBLIC_PROFILE,
			variables: { id: artistId }
		});
		const { '__typename': responseTypeName, ...publicArtistData } = data.data.artist;

		switch (responseTypeName) {
			case ARTIST_PUBLIC_PROFILE_RESPONSE.publicArtist:
				commit('SET_CURRENT_ARTIST', publicArtistData);
				break;

			case ARTIST_PUBLIC_PROFILE_RESPONSE.userNotFound:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('artist.dashboard.client.booking.errors.artistProfileNotFound') }], { root: true });
				router.push({ name: 'ClientDashboard' });
				break;

			case ARTIST_PUBLIC_PROFILE_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
    commit('TOGGLE_LOADING_STATE', false);
  }
};

const FETCH_ARTIST_PUBLIC_PROFILE_MINIATURE = async ({ commit }, artistId) => {
	commit('TOGGLE_LOADING_STATE', true);

  try {
		const data = await apolloProvider.defaultClient.query({
			query: ARTIST_PUBLIC_PROFILE_MINIATURE,
			variables: { id: artistId },
		});
		const { '__typename': responseTypeName, ...publicArtistData } = data.data.artist;
		switch (responseTypeName) {
			case ARTIST_PUBLIC_PROFILE_RESPONSE.publicArtist:
				return publicArtistData;

			case ARTIST_PUBLIC_PROFILE_RESPONSE.userNotFound:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('client.booking.errors.artistProfileNotFound') }], { root: true });
				break;

			case ARTIST_PUBLIC_PROFILE_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
  } catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
    commit('TOGGLE_LOADING_STATE', false);
  }
};

const FETCH_ARTIST_PUBLIC_AVAILABILITIES = async ({ state, commit }, params) => {
  try {
		commit('Availabilities/TOGGLE_IS_AVAILABILITIES_LOADING', true, { root: true });

		const data = await apolloProvider.defaultClient.query({
			query: ARTIST_PUBLIC_AVAILABILITIES,
			variables: params,
			fetchPolicy: 'network-only',
    });
		const publicAvailabilities = data.data.publicAvailabilities;

		publicAvailabilities?.length
			? commit('SET_CURRENT_ARTIST', { ...state.currentArtist, Availabilities: publicAvailabilities })
			: commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
		commit('Availabilities/TOGGLE_IS_AVAILABILITIES_LOADING', false, { root: true });
	}
};

const BOOK_ARTIST = async ({ commit }, params) => {
  commit('TOGGLE_LOADING_STATE', true);

  try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: BOOK_ARTIST_QUERY,
			variables: { input: params },
    });
		const { '__typename': responseTypeName, ...bookArtistData } = data.data.bookArtist;

		switch (responseTypeName) {
			case BOOK_ARTIST_RESPONSE.pendingBooking:
				commit('SET_STRIPE_INTENT', bookArtistData);

				return bookArtistData;
			case BOOK_ARTIST_RESPONSE.artistNotAvailable:
			case BOOK_ARTIST_RESPONSE.artistNotFound:
			case BOOK_ARTIST_RESPONSE.availabilityNotFound:
			case BOOK_ARTIST_RESPONSE.unauthorized:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t(`artist.dashboard.client.booking.errors.${responseTypeName}`) }], { root: true });
				break;

			case BOOK_ARTIST_RESPONSE.unknownError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	} finally {
    commit('TOGGLE_LOADING_STATE', false);
  }
};

const CANCEL_BOOKING = async ({ commit }, { isCancelledByArtist, bookingID }) => {
  try {
		const data = await apolloProvider.defaultClient.mutate({
			mutation: isCancelledByArtist
				? CANCEL_ARTIST_BOOKING_QUERY
				: CANCEL_CLIENT_BOOKING_QUERY,
			variables: { bookingID },
    });
		const { '__typename': responseTypeName, ...cancelBookingData } = data.data[isCancelledByArtist ? 'cancelArtistBooking' : 'cancelClientBooking'];

		switch (responseTypeName) {
			case CANCEL_ARTIST_BOOKING_RESPONSE.artist:
			case CANCEL_CLIENT_BOOKING_RESPONSE.client:
				commit('User/SET_USER', cancelBookingData, { root: true });
				break;

			case CANCEL_CLIENT_BOOKING_RESPONSE.bookingNotFound:
			case CANCEL_CLIENT_BOOKING_RESPONSE.stripeError:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t(`client.booking.errors.${responseTypeName}`) }], { root: true });
				break;

			default:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const GET_INVOICE = async ({ commit }, bookingID) => {
  try {
		const data = await apolloProvider.defaultClient.query({
			query: GET_INVOICE_QUERY,
			variables: { bookingID },
    });
		const { '__typename': responseTypeName, ...getInvoiceData } = data.data.getInvoice;

		switch (responseTypeName) {
			case GET_INVOICE_RESPONSE.invoiceURL:
				return getInvoiceData.URL;

			case GET_INVOICE_RESPONSE.bookingNotFound:
				commit('ADD_ERRORS', [{ type: 'alert', message: 'Booking not found (trad)' }], { root: true });
				break;

			case GET_INVOICE_RESPONSE.unauthorized:
			default:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};


export default {
	FETCH_ARTIST_PUBLIC_PROFILE,
	FETCH_ARTIST_PUBLIC_PROFILE_MINIATURE,
	FETCH_ARTIST_PUBLIC_AVAILABILITIES,
	BOOK_ARTIST,
	CANCEL_BOOKING,
	GET_INVOICE,
};
