import gql      from 'graphql-tag';


export const UNPUBLISH_ARTIST_PROFILE = gql`
  mutation unpublishProfil {
    unpublishProfil {
      __typename

      ... on Artist {
        IsActive
      }

      ... on AlgoliaError {
        message
        Status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
