import { COMMON_API_RESPONSES }			from '../../../constants/common-api-responses.js';


export const GET_STRIPE_URL_RESPONSE = {
    // Success
    setupPaymentUrl: 'SetupPaymentURL',
    dashboardURL: 'DashboardURL',
		// Managed Failures
		...COMMON_API_RESPONSES,
};
