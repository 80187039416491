const SET_USER = (state, user) => {
  state.user = {
    ...state.user,
    ...user
  };
  state.isUserAuthenticated = !!state.user.Token;
};

const TOGGLE_IS_USER_LOADING = (state, isLoading) => {
  state.isUserLoading = isLoading;
};

const TOGGLE_IS_LOADING_PROFILE_PICTURE = (state, isLoading) => {
  state.isLoadingProfilePicture = isLoading;
};

const TOGGLE_IS_LOADING_BANNER_PICTURE = (state, isLoading) => {
  state.isLoadingBannerPicture = isLoading;
};

const AUTHENTICATE = (state, user) => {
  state.isUserAuthenticated = !!user.Token;
};

const LOG_OUT = (state) => {
  state.user = {};
  state.isUserAuthenticated = false;
  state.isUserFetched = false;
};

const SET_LOCALE = (state, locale) => {
  state.locale = locale;
};

const SET_RGPD_ACCEPT = (state, isAccepted) => {
  state.isRGPDAccepted = isAccepted;
};


export default {
  SET_USER,
  TOGGLE_IS_USER_LOADING,
  TOGGLE_IS_LOADING_PROFILE_PICTURE,
  TOGGLE_IS_LOADING_BANNER_PICTURE,
  AUTHENTICATE,
  LOG_OUT,
  SET_LOCALE,
  SET_RGPD_ACCEPT,
};
