import { apolloProvider } 							from '../../graphql/config.js';
import { i18n } 												from '../../main.js';
import { GET_STRIPE_URL } 							from '../../graphql/shared/queries/q-GetStripeUrl';
import { GET_STRIPE_URL_RESPONSE }			from '../../graphql/shared/responses/r-GetStripeUrl';


const FETCH_STRIPE_URL = async ({ commit }) => {
	try {
		const data = await apolloProvider.defaultClient.query({
			query: GET_STRIPE_URL,
			fetchPolicy: 'network-only',
		});
		const { '__typename': responseTypeName, ...stripeData } = data.data.getStripeURL;

		switch (responseTypeName) {
			case GET_STRIPE_URL_RESPONSE.setupPaymentUrl:
			case GET_STRIPE_URL_RESPONSE.dashboardURL:
				commit('SET_STRIPE_URL', stripeData.URL);
				commit('SET_STRIPE_ERROR', false);
				break;

			case GET_STRIPE_URL_RESPONSE.unknownError:
				commit('SET_STRIPE_ERROR', true);
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		const message = error?.graphQLErrors?.[0]?.message ?? i18n.t('common.errors.UnknownError');

		commit('SET_STRIPE_ERROR', true);
		commit('ADD_ERRORS', [{ type: 'alert', message }], { root: true });
	}
};


export default {
  FETCH_STRIPE_URL,
};
