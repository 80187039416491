import {
	IntrospectionFragmentMatcher,
	InMemoryCache
} 																			from 'apollo-cache-inmemory';
import ApolloClient 										from 'apollo-client';
import { ApolloLink, split }						from 'apollo-link';
import { WebSocketLink } 								from '@apollo/client/link/ws';
import { getMainDefinition } 						from 'apollo-utilities';
import VueApollo 												from 'vue-apollo';
import { createUploadLink } 						from 'apollo-upload-client';

import introspectionQueryResultData			from './fragmentTypes.json';
import { API_ERROR_CODES } 							from '../constants/api-errors-codes.js';
import store 														from '../store/index.js';
import router 													from '../router/router.js';


const multipartHeaderQueries = ['uploadProfilPicture', 'uploadMedia'];
const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
const createUploadLinkParameters = {
	uri: process.env.VUE_APP_API_URL,
};
const HttpAndfileUploadLink = createUploadLink(createUploadLinkParameters);
const authenticationLink = new ApolloLink((operation, forward) => {
	const token = store.state.User.user.Token;

	operation.setContext({
		headers: {
			authorization: token ? `Bearer ${token}` : null,
			...(multipartHeaderQueries.includes(operation.operationName)) ? { "content-type": "multipart/form-data" } : {}
		}
	});

	return forward(operation).map(result => {
		const response = result.data[operation.operationName];
		const isUnauthorized = API_ERROR_CODES.AUTH_TOKEN_ERROR.includes(response?.status);

		if (isUnauthorized) {
			router.push(`/${store.getters['User/IS_ARTIST_USER'] ? 'artist' : 'client'}/signin`)
				.finally(() => store.commit('User/LOG_OUT'));
		}

		return result;
	});
});
const wsLink = new WebSocketLink({
	uri: process.env.VUE_APP_WS_URL,
	options: {
		lazy: true,
		timeout: 40000,
		minTimeout: 30000,
    reconnect: true,
		connectionParams: () => store?.state?.User?.user?.Token
			? ({ authToken: `Bearer ${store.state.User.user.Token}` })
			: ({}),
  },
});
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
  },
  wsLink,
  ApolloLink.from([
		authenticationLink,
		HttpAndfileUploadLink,
	]),
);
const apolloClient = new ApolloClient({
	link,
	cache: new InMemoryCache({ fragmentMatcher }),
	connectToDevTools: true,
});
const apolloProvider = new VueApollo({ defaultClient: apolloClient });


export {
	apolloProvider,
	wsLink,
};
