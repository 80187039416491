export default [
  {
    label: 'countriesList.france',
    value: 'FR'
  },
  {
    label: 'countriesList.spain',
    value: 'ES'
  },
  {
    label: 'countriesList.greatBritain',
    value: 'GB'
  },
  {
    label: 'countriesList.belgium',
    value: 'BE'
  },
  {
    label: 'countriesList.switzerland',
    value: 'CH'
  },
];
