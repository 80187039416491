import JWT									from 'jsonwebtoken';

import {
	getCroppedPictureURL,
	getTrimmedVideoURL
}														from '../../utils/URLUtils.js';


const ACTIVITY = (state) => state.user.ActivityInformations;

const PRICINGS = (state, getters) => {
	const { __typename: priceTypeName, ...pricings } = getters.HAS_SETUP_PRICINGS // eslint-disable-line
		? state.user?.Price ?? {}
		: state.user?.DefaultPrices ?? {};

	return Object.entries(pricings)
		.map(([pricingType, pricingPrice]) => ({
			type: pricingType.toLowerCase(),
			price: pricingPrice,
			label: `common.pricingsList.${pricingType.toLowerCase()}`,
			name: `Price${pricingType}`,
	}));
};

const MEDIAS_IMAGES = (state) => {
	return state.user?.Medias
		?.filter(media => media.Type === 'IMAGE')
		?.map(({ Options, URL, ...image }) => ({
				...image,
				originalURL: URL,
				URL: getCroppedPictureURL(URL, Options),
				...(Object.keys(Options).length ? { options: Options } : {}),
			}))
		?.sort((a, b) => a.rank - b.rank)
		?? [];
};

const MEDIAS_VIDEOS = (state) => {
	return state.user?.Medias
		?.filter(media => media.Type === 'VIDEO')
		?.map(({ Options = {}, URL = '', ...video }) => ({
				...video,
				originalURL: URL,
				URL: getTrimmedVideoURL(URL, Options),
				options: Options,
			}))
		?.sort((a, b) => a.rank - b.rank)
		?? [];
};

const MANDATORY_TASKS_TIME_LIMIT = (state) => {
	const timeLimit = new Date(state.user.createdAt);
	const oneDayInMilliseconds = 86400000;

	timeLimit.setDate(timeLimit.getDate() + 30);

	return Math.max(0, Math.floor((timeLimit - new Date()) / oneDayInMilliseconds));
};

const IS_ARTIST_USER = (state) => Boolean(state.isUserAuthenticated && JWT.decode(state?.user?.Token)?.IsArtist);

const HAS_ANSWERED_QUESTIONS = (state) => state.user?.FAQ?.some(question => question.Answer !== '') ?? false;

const PROFILE_PROGRESS_LIST = (state, getters) => {
	const {
		URLProfilePictures,
		URLBannerPictures,
		Description,
	} = state.user;
	const progressList = [
		{
			done: Boolean(Description),
			label: 'artist.dashboard.menu.profilPublish.todoList.description',
			link: 'profile#edit-performance',
			value: 'performance',
			isMandatory: true,
		},
		{
			done: Boolean(URLProfilePictures),
			label: 'artist.dashboard.menu.profilPublish.todoList.profilPicture',
			link: 'profile#profile-picture',
			value: 'profile-picture',
			isMandatory: true,
		},
		{
			done: Boolean(URLBannerPictures),
			label: 'artist.dashboard.menu.profilPublish.todoList.bannerPicture',
			link: 'profile#banner-picture',
			value: 'banner-picture',
			isMandatory: true,
		},
		{
			done: getters.HAS_SET_ADMINISTRATIVE_STATUS,
			label: 'artist.dashboard.menu.profilPublish.todoList.status',
			link: '/artist/payments',
			value: 'status',
			isMandatory: true,
		},
		{
			done: getters.MEDIAS_IMAGES.length > 0,
			label: 'artist.dashboard.menu.profilPublish.todoList.photoMedia',
			link: 'profile#medias-images',
			value: 'images',
		},
		{
			done: getters.MEDIAS_VIDEOS.length > 0,
			label: 'artist.dashboard.menu.profilPublish.todoList.videoMedia',
			link: 'profile#medias-videos',
			value: 'videos',
		},
		{
			done: getters.HAS_ANSWERED_QUESTIONS,
			label: 'artist.dashboard.menu.profilPublish.todoList.faq',
			link: 'profile#faq',
			value: 'faq',
		},
	].sort(item => item.done ? -1 : 1)
   .sort(item => item.isMandatory ? -1 : 1);

	return progressList;
};

const CAN_PUBLISH_PROFILE = (state, getters) => {
	return getters['PROFILE_PROGRESS_LIST']
		.filter(({ isMandatory }) => isMandatory)
		.every(({ done }) => done);
};

const PROFILE_PROGRESS = (state, getters) => {
	const {
		URLProfilePictures,
		URLBannerPictures,
		Description,
	} = state.user;
	const tasks = [
		URLProfilePictures,
		URLBannerPictures,
		Description,
		getters.MEDIAS_IMAGES.length > 0,
		getters.MEDIAS_VIDEOS.length > 0,
		getters.HAS_ANSWERED_QUESTIONS,
		getters.HAS_SET_ADMINISTRATIVE_STATUS,
	];
	const progress = Math.floor(tasks.filter(Boolean).length / tasks.length * 100);

	return progress;
};

const HAS_SETUP_PRICINGS = (state) => {
	if (!state.user.Price) return false;

	const { __typename: priceTypeName, ...pricings } = state.user.Price; // eslint-disable-line

	return Object.values(pricings).every(pricing => pricing !== null);
};

const HAS_SET_IDENTITY = (state, getters) => {
	const {
		Birthday,
		Firstname,
		Lastname,
		Gender,
		Line1,
		PostalCode,
		City,
		Country,
	} = state.user;

	return Boolean(
		(getters.IS_ARTIST_USER ? Birthday : true)
		&& Gender && Firstname
		&& Lastname && Line1
		&& City && PostalCode && Country
	);
};

const HAS_SET_ACTIVITY = (state) => {
	const {
		ActivityInformations,
		ChildrenPermission,
	} = state.user;

	return Boolean(
		ActivityInformations
		&& !['NotSet', 'NotFetched'].includes(ActivityInformations.__typename)
		&& Boolean(ChildrenPermission)
	);
};

const HAS_SET_ADMINISTRATIVE_STATUS = (state) => {
	const {
		Status,
		StatusName,
		URLCardID,
		Birthday,
		IsStripeSetup,
	} = state.user;

	if (!Status) return false;
	if (StatusName !== 'INTERMITTENT') return Status.Siret
		&& Status.KBISUrl
		&& Status.IsRCPro
		&& IsStripeSetup
		&& URLCardID;

	return Status.Nationality
		&& Status.PlaceOfBirth
		&& Status.StateOfBirth
		&& Status.CountryOfBirth
		&& Status.SocialSecurityNumber
		&& Status.ShowLeaveNumber
		&& Status.Iban
		&& Status.Bic
		&& Birthday
		&& URLCardID;
};

const CURRENT_EMBOARDING_STEP = (state, getters) => {
	if (!getters['HAS_SET_IDENTITY']) return getters['IS_ARTIST_USER'] ? 'ArtistEmboardingIdentity' : 'ClientEmboardingIdentity';
	if (!state.user.IsPhoneNumberConfirmed) return getters['IS_ARTIST_USER'] ? 'ArtistEmboardingTelephone' : 'ClientEmboardingTelephone';
	if (!getters['HAS_SET_ACTIVITY']) return 'ArtistEmboardingActivity';
};

const HAS_COMPLETED_EMBOARDING = (state, getters) => Boolean(
	(state.user.IsPhoneNumberConfirmed || (!getters['IS_ARTIST_USER'] && state.user.PhoneNumber)) &&
	getters['HAS_SET_IDENTITY'] &&
	(getters['IS_ARTIST_USER'] ? getters['HAS_SET_ACTIVITY'] : true)
);


export default {
	ACTIVITY,
	PRICINGS,
	HAS_SETUP_PRICINGS,
	MEDIAS_IMAGES,
	MEDIAS_VIDEOS,
	MANDATORY_TASKS_TIME_LIMIT,
	IS_ARTIST_USER,
	HAS_ANSWERED_QUESTIONS,
	PROFILE_PROGRESS,
	PROFILE_PROGRESS_LIST,
	CURRENT_EMBOARDING_STEP,
	HAS_COMPLETED_EMBOARDING,
	HAS_SET_IDENTITY,
	HAS_SET_ACTIVITY,
	HAS_SET_ADMINISTRATIVE_STATUS,
	CAN_PUBLISH_PROFILE,
};
