import { v4 as generateUUID }     from 'uuid';


const ADD_ERRORS = (state, errors = []) => {
  const errorsWithID = errors.map(error => ({ ...error, id: generateUUID() }));

  state.errors.push(...errorsWithID);
};

const CLEAR_ERROR = (state, error = {}) => {
  state.errors = state.errors.filter(({ id }) => id !== error.id);
};

const CLEAR_ERRORS = (state) => state.errors = [];


export default {
  ADD_ERRORS,
  CLEAR_ERROR,
  CLEAR_ERRORS,
};
