import { isCurrentOrFutureDate }			from '../../utils/dateUtils';
import countriesList									from '../../constants/countriesList.js';


const CALENDAR_AVAILABILITIES = (state, getters, rootState) => {
	const bookings = rootState.User.user.Bookings ?? [];
	const calendarAvailabilities = state.availabilities.map(availability => {
		const isBookedAvailability = bookings.some(({ EventDate }) => EventDate === availability.EventDate);

		return {
			key: isBookedAvailability
				? `${availability.EventType.toLowerCase()}-${availability.Id}-booked`
				: `${availability.EventType.toLowerCase()}-${availability.Id}`,
			dates: [availability.EventDate * 1000],
			customData: availability,
		};
	});

	return calendarAvailabilities;
};

const CALENDAR_PENDING_AVAILABILITIES = (state) => {
	const calendarAvailabilities = state.availabilities.map(availability => {
		const pendingAvailability = state.pendingAvailabilitiesUpdates.find(({ Id }) => Id === availability.Id);
		const updatedAvailability = pendingAvailability || availability;

		return {
			key: `${updatedAvailability.EventType.toLowerCase()}-${updatedAvailability.Id}`,
			dates: [updatedAvailability.EventDate * 1000],
			customData: updatedAvailability,
		};
	});

	return calendarAvailabilities;
};

const UPCOMING_PERFORMANCES = (state, getters, rootState) => {
	const bookings = rootState.User.user.Bookings ?? [];
	const upcomingPerformances = bookings
		.filter(booking => isCurrentOrFutureDate(booking.EventDate * 1000))
		.map(booking => ({
			type: booking.EventType.toLowerCase(),
			adress: booking.Line1,
			date: booking.EventDate * 1000,
			postalCode: booking.PostalCode,
			city: booking.City,
			country: countriesList.find(({ value }) => value === booking.Country),
			id: booking.ID,
			startTime: booking.StartTime,
			amount: booking.Amount/100,
			fees: booking.Fees/100,
			client: {
				fullname: `${booking.Client.Firstname} ${booking.Client.Lastname}`,
				email: booking.Client.Email,
				phoneNumber: booking.Client.PhoneNumber,
			},
		}));

	return upcomingPerformances;
};


export default {
	CALENDAR_AVAILABILITIES,
	CALENDAR_PENDING_AVAILABILITIES,
	UPCOMING_PERFORMANCES,
};
