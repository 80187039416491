import {
	getCroppedPictureURL,
}															from '../../utils/URLUtils.js';
import {
	getActivityTranslation,
}												 			from '../../utils/i18nUtils.js';


const FEATURED_ARTISTS_PROFILES = (state) => {
	return state?.landingData?.FeaturedArtists?.map(({ Activity, PictureCropOption, Picture, ...artist }) => ({
		...artist,
		Activity: getActivityTranslation(Activity),
		ProfilePictureURL: getCroppedPictureURL(Picture, PictureCropOption),
	})) ?? [];
};

export default {
  FEATURED_ARTISTS_PROFILES,
};