const RESET_STORE = ({ commit }) => {
  commit('Availabilities/CLEAR_STATE');
  commit('Landing/CLEAR_STATE');
  commit('Layout/CLEAR_STATE');
  commit('Routing/CLEAR_STATE');
  commit('SearchArtist/CLEAR_STATE');
  commit('Stripe/CLEAR_STATE');
};


export default {
  RESET_STORE,
};
